import { useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Collapse, ListItem } from '@material-ui/core';
import type { ListItemProps } from '@material-ui/core';
import ChevronDownIcon from '../icons/ChevronDown';
import ChevronRightIcon from '../icons/ChevronRight';
import DashboardSidebarProjectPinner from './dashboard/DashboardSidebarProjectPinner';

interface NavItemProps extends ListItemProps {
  active?: boolean;
  children?: ReactNode;
  depth: number;
  icon?: ReactNode;
  info?: ReactNode;
  open?: boolean;
  path?: string;
  title: string;
  disableCollapse?: boolean;
  iconEnd?: boolean;
}

const NavItem: FC<NavItemProps> = (props) => {
  const {
    active,
    children,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    disableCollapse,
    iconEnd,
    ...other
  } = props;
  const [open, setOpen] = useState<boolean>(openProp);

  useEffect(() => {
    const storedOpen = localStorage.getItem(title) === '1';
    setOpen(storedOpen);
  }, []);

  const handleToggle = (e: any, force: boolean = false): void => {
    if (disableCollapse && !force) {
      return;
    }

    setOpen((prevOpen) => !prevOpen);
  };

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  if (title === 'projectPinner') {
    return <DashboardSidebarProjectPinner />;
  }

  useEffect(() => {
    localStorage.setItem(title, open ? '1' : '0');
  }, [open]);

  // Branch
  if (children) {
    return (
      <ListItem
        disableGutters
        sx={{
          display: 'block',
          py: 0,
        }}
        {...other}
      >
        <Button
          endIcon={
            !open ? (
              <ChevronRightIcon
                fontSize="small"
                onClick={(e) => {
                  handleToggle(null, true);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            ) : (
              <ChevronDownIcon
                fontSize="small"
                onClick={(e) => {
                  handleToggle(null, true);
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            )
          }
          onClick={handleToggle}
          startIcon={icon}
          sx={{
            color: 'text.secondary',
            fontWeight: 'fontWeightMedium',
            justifyContent: 'flex-start',
            pl: `${paddingLeft}px`,
            pr: '8px',
            py: '12px',
            textAlign: 'left',
            textTransform: 'none',
            width: '100%',
          }}
          variant="text"
        >
          <Box sx={{ flexGrow: 1 }}>{title}</Box>
          {info}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        py: 0,
      }}
    >
      <Button
        component={path && RouterLink}
        startIcon={!iconEnd && icon}
        endIcon={iconEnd && icon}
        sx={{
          color: 'text.secondary',
          fontWeight: 'fontWeightMedium',
          justifyContent: 'flex-start',
          textAlign: 'left',
          pl: `${paddingLeft}px`,
          pr: '8px',
          py: '12px',
          textTransform: 'none',
          width: '100%',
          ...(active && {
            color: 'primary.main',
            fontWeight: 'fontWeightBold',
            '& svg': {
              color: 'primary.main',
            },
          }),
        }}
        variant="text"
        to={path}
      >
        <Box sx={{ flexGrow: 1 }}>{title}</Box>
        {info}
      </Button>
    </ListItem>
  );
};

NavItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  disableCollapse: PropTypes.bool,
  iconEnd: PropTypes.bool,
};

NavItem.defaultProps = {
  active: false,
  open: false,
};

export default NavItem;
