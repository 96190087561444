import { User } from '../types/user';
import { storage as s } from '../utils/storage';
import { axiosGraqhqlInstance } from 'src/api/axios';
import { UserUpdateInput } from 'src/contexts/UserContext';
import { NotificationValue } from 'src/types/notification';

class AuthApi {
  async login(email: string, password: string, expiresIn?: string) {
    return axiosGraqhqlInstance.post<{
      userLogin: User;
    }>('graphql', {
      query: `mutation UserLogin($email: String!, $password: String!, $expiresIn: String) {
        userLogin(email: $email, password: $password, expiresIn: $expiresIn) {
          _id
          email
          firstName
          lastName
          token
          phoneNumber
          country
          avatar
        }
      }`,
      variables: {
        email,
        password,
        expiresIn: expiresIn || '8h',
      },
    });
  }

  async register(email: string, firstName: string, lastName: string, password: string) {
    return axiosGraqhqlInstance.post<{
      userCreate: User;
    }>('graphql', {
      query: `mutation UserCreate($email: String!, $firstName: String!, $password: String!, $lastName: String) {
        userCreate(email: $email, firstName: $firstName, password: $password, lastName: $lastName) {
          _id
          email
          token
          firstName
          lastName
          avatar
        }
      }`,
      variables: { email, firstName, lastName, password },
    });
  }

  async me(token: string) {
    axiosGraqhqlInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    return axiosGraqhqlInstance.post<{
      me: User;
    }>('graphql', {
      query: `query Me {
        me {
          _id
          email
          firstName
          lastName
          phoneNumber
          country
          avatar
          userSettings
          notifications {
            label
            type
            active
            push
            email
            text
          }
        }
      }`,
    });
  }

  async passwordRecovery(email: string) {
    return axiosGraqhqlInstance.post<{
      passwordRecovery: string;
    }>('graphql', {
      query: `mutation PasswordRecovery($email: String!) {
          passwordRecovery(email: $email)
      }`,
      variables: { email },
    });
  }

  async passwordReset(token: string, password: string, passwordCheck: string) {
    return axiosGraqhqlInstance.post<{
      passwordSet: string;
    }>('graphql', {
      query: `mutation PasswordSet($token: String!, $password: String!, $passwordCheck: String!) {
        passwordSet(password: $password, passwordCheck: $passwordCheck, token: $token)
      }`,
      variables: { token, password, passwordCheck },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async userUpdate(updates: UserUpdateInput) {
    return axiosGraqhqlInstance.post<{
      updateUser: User;
    }>('graphql', {
      query: `mutation UpdateUser($id: String!, $email: String, $firstName: String, $lastName: String, $phoneNumber: String, $country: String, $avatar: String) {
        updateUser(id: $id, email: $email, firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, country: $country, avatar: $avatar) {
          _id
          email
          firstName
          lastName
          phoneNumber
          country
          avatar
        }
      }`,
      variables: { ...updates },
    });
  }

  async passwordUpdate(id: string, newPassword: string, checkPassword: string) {
    return axiosGraqhqlInstance.post<{
      updatePassword: string;
    }>('graphql', {
      query: `mutation UpdatePassword($userId: String!, $newPassword: String!, $checkPassword: String!) {
        updatePassword(newPassword: $newPassword, checkPassword: $checkPassword, userId: $userId)
      }`,
      variables: { userId: id, newPassword, checkPassword },
    });
  }

  async setNotificationsFlag(values: NotificationValue[]): Promise<NotificationValue[]> {
    axiosGraqhqlInstance.defaults.headers.common.Authorization = `Bearer ${s.recursive('user/token')}`;
    const response = await axiosGraqhqlInstance.post<{
      userSetNotificationsFlag: NotificationValue[];
    }>('graphql', {
      query: `mutation UserSetNotificationsFlag($values: [NotificationLineInput]) {
        userSetNotificationsFlag(values: $values) {
          label
          type
          active
          push
          email
          text
        }
      }`,
      variables: { values },
    });

    return response.data.userSetNotificationsFlag;
  }
}

export const authApi = new AuthApi();
