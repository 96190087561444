import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
import { FilterProvider } from './contexts/ProjectFilterContext';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));
const Invitation = Loadable(lazy(() => import('./pages/Invitation')));

// Dashboard pages
const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/Overview')));

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

// Projects pages
const ProjectBrowse = Loadable(lazy(() => import('./pages/dashboard/ProjectBrowse')));
const ProjectCreate = Loadable(lazy(() => import('./pages/dashboard/ProjectCreate')));
const ProjectDetails = Loadable(lazy(() => import('./pages/dashboard/ProjectDetails')));
const ProjectArchived = Loadable(lazy(() => import('./pages/dashboard/ProjectArchived')));

// Deliverables pages
const DeliverableCreate = Loadable(lazy(() => import('./pages/dashboard/DeliverableCreate')));
const DeliverableView = Loadable(lazy(() => import('./pages/dashboard/DeliverableView')));
const BugCreate = Loadable(lazy(() => import('./pages/dashboard/BugCreate')));
const BugView = Loadable(lazy(() => import('./pages/dashboard/BugView')));

// Requests pages
const RequestCreate = Loadable(lazy(() => import('./pages/dashboard/RequestCreate')));
const RequestView = Loadable(lazy(() => import('./pages/dashboard/RequestView')));

// Social pages
const SocialProfile = Loadable(lazy(() => import('./pages/dashboard/SocialProfile')));

// Sprint pages
const SprintCreate = Loadable(lazy(() => import('./pages/dashboard/SprintCreate')));
const SprintView = Loadable(lazy(() => import('./pages/dashboard/SprintView')));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        ),
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />,
      },
      {
        path: 'password-reset',
        children: [
          {
            path: ':code',
            element: <PasswordReset />,
          },
        ],
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        ),
      },
      {
        path: 'verify-code',
        element: <VerifyCode />,
      },
    ],
  },
  {
    path: 'invitation',
    children: [
      {
        path: ':key',
        element: (
          <AuthGuard>
            <Invitation />
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: '',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate
          to="dashboard"
          replace
        />,
      },
      {
        path: 'dashboard',
        children: [
          {
            path: '',
            element: <Overview />,
          },
          {
            path: 'projects',
            children: [
              {
                path: '',
                element: <Navigate
                  to="browse"
                  replace
                />,
              },
              {
                path: 'browse',
                element: (
                  <FilterProvider>
                    <ProjectBrowse />
                  </FilterProvider>
                ),
              },
              {
                path: 'archived',
                element: (
                  <FilterProvider>
                    <ProjectArchived />
                  </FilterProvider>
                ),
              },
              {
                path: 'new',
                element: <ProjectCreate />,
              },
              {
                path: ':projectId',
                children: [
                  {
                    // @todo: find out another way that could use user settings (projects.defaultTab)
                    path: '',
                    element: <Navigate
                      to="overview"
                      replace
                    />,
                  },
                  {
                    path: 'overview',
                    element: <ProjectDetails />,
                  },
                  {
                    path: 'activities',
                    children: [
                      {
                        path: '',
                        element: <ProjectDetails />,
                      },
                    ],
                  },
                  {
                    path: 'deliverables',
                    children: [
                      {
                        path: '',
                        element: <ProjectDetails />,
                      },
                      {
                        path: 'new',
                        element: <DeliverableCreate />,
                      },
                      {
                        path: ':issueId',
                        element: <DeliverableView />,
                      },
                    ],
                  },
                  {
                    path: 'sprint',
                    children: [
                      {
                        path: '',
                        element: <ProjectDetails />,
                      },
                      {
                        path: 'new',
                        element: <SprintCreate />,
                      },
                      {
                        path: ':issueId',
                        element: <SprintView />,
                      },
                    ],
                  },
                  {
                    path: 'bugs',
                    children: [
                      {
                        path: 'new',
                        element: <BugCreate />,
                      },
                      {
                        path: ':issueId',
                        element: <BugView />
                      }
                    ],
                  },
                  {
                    path: 'requests',
                    children: [
                      {
                        path: '',
                        element: <ProjectDetails />,
                      },
                      {
                        path: 'new',
                        element: <RequestCreate />,
                      },
                      {
                        path: ':issueId',
                        element: <RequestView />,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'account',
        element: <Account />,
      },
      {
        path: 'profile',
        element: <SocialProfile />,
      },
    ],
  },
  {
    path: '*',
    element: <MainLayout />,
    children: [
      {
        path: '',
        element: <Navigate
          to="/dashboard"
          replace
        />,
      },
      {
        path: '401',
        element: <AuthorizationRequired />,
      },
      {
        path: '404',
        element: <NotFound />,
      },
      {
        path: '500',
        element: <ServerError />,
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
];

export default routes;
