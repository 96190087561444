/* eslint-disable @typescript-eslint/naming-convention */
import { axiosRestInstance } from './axios';
import { storage as s } from '../utils/storage';
import type { FileType } from '../types/file';

export enum UPLOAD_DESTINATION {
  SYSTEM = 'SYSTEM',
  JIRA_ISSUE = 'JIRA_ISSUE',
  JIRA_PROJECT = 'JIRA_PROJECT',
}

export interface DeleteQuery {
  id?: string;
  refId?: string | number;
  location?: UPLOAD_DESTINATION;
}

class FileApi {
  axios: typeof axiosRestInstance;

  constructor(private token?: string) {
    this.axios = axiosRestInstance;
    this.axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
  }

  /**
   * Upload files either to filesystem or to jira
   *
   * @param files - Files to upload
   * @param destination - SYSTEM or JIRA
   * @param refId - Needed if destination is JIRA. Should be id of issue
   * @returns
   */
  async upload(files: any[], destination?: UPLOAD_DESTINATION, refId?: string): Promise<FileType[]> {
    const formData = new FormData();
    files.forEach((file: any) => formData.append('file', file));

    let url = `files/multi?dest=${destination || UPLOAD_DESTINATION.SYSTEM}`;
    const uploadingToJira = !!destination && destination !== UPLOAD_DESTINATION.SYSTEM;

    // Forbid uploading to jira without refId
    if (uploadingToJira && !refId) {
      throw new Error('RefId is mandatory when uploading to JIRA');
    }
    if (uploadingToJira) {
      url += `&refId=${refId}`;
    }

    const results = await this.axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return results.data;
  }

  async delete(req: DeleteQuery, isJiraId?: boolean): Promise<any> {
    let suffix = '';
    if (req.location) {
      suffix += `&location=${req.location}`;
    }

    if (req.refId) {
      suffix += `&refId=${req.refId}`;
    }

    const response = await this.axios.delete(`files/${req.id}?idType=${isJiraId ? 'JIRA' : 'LOCAL'}${suffix}`);

    return response;
  }
}

export const fileApi = new FileApi(s.recursive('user.token'));
