/* eslint-disable react/no-array-index-key */
import { useState } from 'react';
import type { FC, KeyboardEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Drawer,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import SearchIcon from '../../icons/Search';
import XIcon from '../../icons/X';
import Scrollbar from '../Scrollbar';
import { CollectionObject } from '../../types/jira';
import { JiraProject as Project } from '../../types/project';
import { projectApi as api } from '../../api/projectApi';

const ContentSearch: FC = () => {
  const [value, setValue] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showResults, setShowResults] = useState<boolean>(false);
  const [projects, setProjects] = useState<Project[]>([]);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const search = async (): Promise<void> => {
    setShowResults(false);
    setIsLoading(true);
    // Do search here
    // @todo: include issue in research result, group by project
    api
      .search({
        expand: 'description,lead,insight',
        status: 'live',
        orderBy: '-lastIssueUpdatedTime',
      })
      .then((result: CollectionObject<Project>) => {
        setProjects(result.values);
      })
      .finally(() => {
        setIsLoading(false);
        setShowResults(true);
      });
  };

  const handleClick = (): void => {
    search();
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === 'ENTER') {
      search();
    }
  };

  return (
    <>
      <Tooltip title="Search">
        <IconButton
          color="inherit"
          onClick={handleOpen}
        >
          <SearchIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="top"
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: '100%' },
        }}
        variant="temporary"
      >
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <IconButton onClick={handleClose}>
              <XIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Box sx={{ p: 3 }}>
          <Container maxWidth="md">
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                onChange={(event): void => setValue(event.target.value)}
                onKeyUp={handleKeyUp}
                placeholder="Search..."
                value={value}
              />
              <Button
                color="primary"
                onClick={handleClick}
                size="large"
                sx={{ ml: 2 }}
                variant="contained"
              >
                Search
              </Button>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Scrollbar options={{ suppressScrollX: true }}>
                {isLoading ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    {showResults && (
                      <>
                        {projects.map((project: Project, i: number) => (
                          <Box
                            key={i}
                            sx={{ mb: 2, marginBottom: 5 }}
                          >
                            <Link
                              color="textPrimary"
                              component={RouterLink}
                              to={`/dashboard/projects/${project.id}`}
                              variant="h5"
                            >
                              {project.name}
                            </Link>
                            <Typography
                              color="textPrimary"
                              variant="body2"
                              dangerouslySetInnerHTML={{
                                __html: (project?.description || '')
                                  .split('*What is the main purpose of this project?*')
                                  .pop()
                                  .substr(0, 150),
                              }}
                            />
                          </Box>
                        ))}
                      </>
                    )}
                  </>
                )}
              </Scrollbar>
            </Box>
          </Container>
        </Box>
      </Drawer>
    </>
  );
};

export default ContentSearch;
