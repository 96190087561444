import { axiosGraqhqlInstance } from 'src/api/axios';
import { storage as s } from '../utils/storage';
import type { Notification, NotificationQuery } from '../types/notification';
import type { RowsCollection } from '../types/collection';

declare type RowsOf<D> = RowsCollection<D> & { countUnread: number };

class NotificationApi {
  axios: typeof axiosGraqhqlInstance;

  constructor(private token?: string) {
    this.axios = axiosGraqhqlInstance;
    this.axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async query({ query, page, pageSize, displayOnlyUnread }: NotificationQuery): Promise<RowsOf<Notification>> {
    const response = await this.axios.post<{
      notificationsList: RowsOf<Notification>;
    }>('graphql', {
      // ATTENTION: when updating this make sure to format notification object
      // in backend/mixins/services/users.notifications.mixin.ts var: pushPayload
      query: `
        query NotificationsList($query: JSON, $page: Int, $pageSize: Int) {
          notificationsList(query: $query, page: $page, pageSize: $pageSize) {
            total
            totalPages
            page
            pageSize
            countUnread
            rows {
              _id
              type
              createdAt
              read
              user {
                displayName
                avatar
              }
              issue {
                id
                key
                fields {
                  summary
                  issuetype {
                    id
                    name
                    iconUrl
                  }
                  status {
                    id
                    name
                  }
                }
              }
              data
            }
          }
        }
      `,
      variables: {
        query: {
          ...(query || {}),
          // ...(displayOnlyUnread && {
          //   read: false,
          // })
        },
        page: page || 1,
        pageSize: pageSize || 10,
      },
    });

    return response.data.notificationsList;
  }

  // @note not in use, can drop safely
  async unreadList({ query }): Promise<RowsCollection<Notification>> {
    const response = await this.axios.post<{
      notificationsUnreadList: RowsCollection<Notification>;
    }>('graphql', {
      query: `
        query NotificationsUnreadList($query: JSON) {
          notificationsUnreadList(query: $query) {
            rows {
              _id
              type
              createdAt
              read
              user {
                displayName
                avatar
              }
              issue {
                id
                key
                fields {
                  summary
                  issuetype {
                    id
                    name
                    iconUrl
                  }
                  status {
                    id
                    name
                  }
                }
              }
              data
            }
          }
        }
      `,
      variables: {
        query: query || {},
      },
    });

    return response.data.notificationsUnreadList;
  }

  async bulkRead(ids: string[], value?: boolean): Promise<any> {
    const response = await this.axios.post<{
      notificationBulkRead: any;
    }>('graphql', {
      query: `
        mutation NotificationsBulkRead($ids: [String], $value: Boolean) {
          notificationBulkRead(ids: $ids, value: $value)
        }
      `,
      variables: {
        ids,
        value: typeof value === 'boolean' ? value : true,
      },
    });

    return response.data.notificationBulkRead;
  }

  async readAll(): Promise<any> {
    const response = await this.axios.post<{
      notificationsMarkAllAsRead: any;
    }>('graphql', {
      query: `
        mutation {
          notificationsMarkAllAsRead
        }
      `,
    });

    return response.data.notificationsMarkAllAsRead;
  }
}

export default new NotificationApi(s.recursive('user.token'));
