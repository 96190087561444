/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { projectApi } from '../api/projectApi';
import { fileApi, UPLOAD_DESTINATION } from '../api/fileApi';
import type { FileType } from 'src/types/file';
import { parseError } from 'src/utils/error';
import { jiraConfig } from 'src/config';
import type { ProjectInput, JiraProject as Project, ProjectQuery, MembershipRaw } from '../types/project';
import { loadProjectSettings } from './settings';

interface Paginate {
  total: number;
  maxResults: number;
  isLast: boolean;
  startAt: number;
}

interface ProjectState {
  projects: Project[];
  current: Project | null;
  processing: boolean;
  loaded: boolean;
  error: Error | null;
  paginate: Paginate;
  files: FileType[];
  filesProcessing: boolean;
  fileDeleteProcessing: string | null;
}

const initialState: ProjectState = {
  projects: [],
  current: null,
  processing: false,
  loaded: false,
  error: null,
  paginate: {
    startAt: 0,
    total: 0,
    maxResults: 0,
    isLast: false,
  },
  files: [],
  filesProcessing: false,
  fileDeleteProcessing: null,
};

export const createProject = createAsyncThunk<Project, ProjectInput>(
  'project/create',
  async (createData: ProjectInput, { rejectWithValue }) => {
    const { files, ...postDatas } = createData;
    const hasFiles = createData.files && Array.isArray(createData.files) && createData.files.length > 0;

    try {
      const project = await projectApi.create(postDatas);
      if (hasFiles) {
        // upload files and attach to JIRA project
        await fileApi.upload(files, UPLOAD_DESTINATION.JIRA_PROJECT, project.id);
      }

      return project;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const updateProject = createAsyncThunk<Project, ProjectInput & { id: number }>(
  'project/update',
  async ({ id, ...payload }, { rejectWithValue }) => {
    try {
      console.log(id);
      console.log(payload);
      const res = await projectApi.update(id, payload);

      return res;
    } catch (e) {
      const error = e;
      if (!error.response) {
        throw e;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const deleteProject = createAsyncThunk<any, { id: number }>(
  'project/delete',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const { project }: any = getState();
      const res = await projectApi.deleteProject(project?.current?.key, id.toString());

      return res;
    } catch (e) {
      const error = e;
      if (!error.response) {
        throw e;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const archiveProject = createAsyncThunk<any, { id: number }>(
  'project/archive',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const { project }: any = getState();
      const res = await projectApi.archiveProject(project?.current?.key, id.toString());

      return res;
    } catch (e) {
      const error = e;
      if (!error.response) {
        throw e;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const restoreProject = createAsyncThunk<any, { id: number }>(
  'project/restore',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const { project }: any = getState();

      const currentProject = project.projects.find((item) => item.id === id);

      const res = await projectApi.restoreProject(currentProject?.key, id.toString());

      return res;
    } catch (e) {
      const error = e;
      if (!error.response) {
        throw e;
      }

      return rejectWithValue(error.response);
    }
  }
);

export const fetchProjects = createAsyncThunk<{ values: Project[] } & Paginate, ProjectQuery>(
  'project/fetch',
  async (query: ProjectQuery = {}, { rejectWithValue }) => {
    try {
      const result = await projectApi.search(query);

      return result;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const getProject = createAsyncThunk<Project, { id: string } & ProjectQuery>(
  'project/get',
  async ({ id, ...query }, { rejectWithValue, dispatch }) => {
    try {
      const project = await projectApi.get(id, query);

      await dispatch(
        loadProjectSettings({
          projectId: project.id as number,
          fieldId: jiraConfig.custom_field.WHO.value,
          issueTypeId: jiraConfig.issueType.DELIVERABLE.value.toString(),
        })
      );

      return project;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const getFiles = createAsyncThunk<any, { id: string }>(
  'project/fetch/files',
  async ({ id }, { rejectWithValue }) => {
    try {
      const res = await projectApi.getFiles(id);

      return res;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const deleteFile = createAsyncThunk<any, { id: string }>(
  'project/delete/file',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      // @ts-ignore
      const { project } = getState();

      const res = await fileApi.delete({
        id,
        location: UPLOAD_DESTINATION.JIRA_PROJECT,
        refId: project?.current?.id,
      });

      return res;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const inviteMember = createAsyncThunk<any, { email: string; role: string }>(
  'project/membership/invite',
  async ({ email, role }, { rejectWithValue, getState }) => {
    try {
      const { project }: any = getState();
      const res = await projectApi.inviteMember(project?.current?.key, email, role);

      return res;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const changeMemberRole = createAsyncThunk<any, { userId: string; role: string }>(
  'project/membership/change',
  async ({ userId, role }, { rejectWithValue, getState }) => {
    try {
      const { project }: any = getState();
      const res = await projectApi.changeMemberRole(project?.current?.key, userId, role);

      return res;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const removeMember = createAsyncThunk<any, { id: string; checkBy?: string }>(
  'project/membership/revoke',
  async ({ id, checkBy }, { rejectWithValue, getState }) => {
    try {
      const { project }: any = getState();
      const res = await projectApi.removeMember(project?.current?.key, id, checkBy || 'userId');

      return res;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

const initProcess = (state: ProjectState) => ({
  ...state,
  error: null,
  loaded: false,
  processing: true,
});

const slice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    setError(state: ProjectState, action: PayloadAction<Error>) {
      state.error = action.payload;
    },
    setPaginate(state: ProjectState, action: PayloadAction<Paginate>) {
      state.paginate = action.payload;
    },
    resetCurrent(state: ProjectState) {
      state.current = null;
      state.error = null;
      state.loaded = false;
      state.processing = false;
    },
  },
  extraReducers: (builder) => {
    // create project
    builder.addCase(createProject.pending, initProcess);

    builder.addCase(createProject.rejected, (state: ProjectState, action: any) => {
      state.error = parseError(action);
      state.loaded = false;
      state.processing = false;
    });

    builder.addCase(createProject.fulfilled, (state: ProjectState, action: PayloadAction<Project>) => {
      state.projects.push(action.payload);
      state.current = action.payload;
      state.loaded = true;
      state.processing = false;
    });

    // update project
    builder.addCase(updateProject.pending, initProcess);

    builder.addCase(updateProject.rejected, (state: ProjectState, action: any) => {
      state.error = parseError(action);
      state.loaded = false;
      state.processing = false;
    });

    builder.addCase(updateProject.fulfilled, (state: ProjectState, action: PayloadAction<Project>) => {
      // update current project
      const { properties, ...currentValue }: Project = state.current;
      const overide: any = {
        ...currentValue,
        ...action.payload,
        properties: {
          ...(properties || {}),
          ...(action.payload?.properties || {}),
        },
      } as Project;
      state.current = overide;

      // update project item in the list (may not needed as the list is always fetch on each load)

      state.loaded = true;
      state.processing = false;
    });

    // search project
    builder.addCase(fetchProjects.pending, initProcess);

    builder.addCase(fetchProjects.rejected, (state: ProjectState, action: any) => {
      state.error = parseError(action);
      state.loaded = false;
      state.processing = false;
    });

    builder.addCase(
      fetchProjects.fulfilled,
      (state: ProjectState, action: PayloadAction<{ values: Project[] } & Paginate>) => {
        const { values, ...paginate } = action.payload;
        console.log(action.payload);
        state.projects = values;
        state.paginate = paginate;
        state.loaded = true;
        state.processing = false;
        state.error = null;
      }
    );

    // get project
    builder.addCase(getProject.pending, initProcess);

    builder.addCase(getProject.rejected, (state: ProjectState, action: any) => {
      state.error = parseError(action);
      state.loaded = false;
      state.processing = false;
    });

    builder.addCase(getProject.fulfilled, (state: ProjectState, action: PayloadAction<Project>) => {
      console.log('get project ', action);
      state.current = action.payload;
      state.loaded = true;
      state.processing = false;
      state.files = action.payload.properties.files || [];
    });

    // Get project files
    builder.addCase(getFiles.pending, (state: ProjectState) => ({
      ...state,
      filesProcessing: true,
    }));

    builder.addCase(getFiles.rejected, (state: ProjectState, action: any) => {
      state.filesProcessing = false;
      state.error = parseError(action);
    });

    builder.addCase(getFiles.fulfilled, (state: ProjectState, action: any) => {
      state.filesProcessing = false;
      state.files = action.payload;
    });

    // Delete project file
    builder.addCase(deleteFile.pending, (state: ProjectState, action: any) => {
      state.fileDeleteProcessing = action?.meta?.arg?.id || null;
    });

    builder.addCase(deleteFile.rejected, (state: ProjectState, action: any) => {
      state.fileDeleteProcessing = null;
      state.error = parseError(action);
    });

    builder.addCase(deleteFile.fulfilled, (state: ProjectState) => {
      state.fileDeleteProcessing = null;
    });

    builder.addCase(inviteMember.fulfilled, (state: ProjectState, action: PayloadAction<MembershipRaw, any, any>) => {
      state.current.membership.push(action.payload);
    });

    builder.addCase(
      changeMemberRole.fulfilled,
      (state: ProjectState, action: PayloadAction<{ role: string }, any, any>) => {
        state.current.membership = state.current.membership.map((m: MembershipRaw) => {
          // eslint-disable-next-line no-underscore-dangle
          if (m.user?._id === action.meta?.arg?.userId) {
            return {
              ...m,
              role: action.payload.role,
            };
          }

          return m;
        });
      }
    );

    builder.addCase(removeMember.fulfilled, (state: ProjectState, action: PayloadAction<number>) => {
      if (action.payload >= 0) {
        state.current.membership.splice(action.payload, 1);
      }
    });

    builder.addCase(deleteProject.pending, (state: ProjectState) => {
      state.processing = true;
      state.loaded = false;
    });

    builder.addCase(deleteProject.fulfilled, (state: ProjectState, action: PayloadAction<any, any, { arg?: { id: number } }>) => {
      const projectId = action?.meta?.arg?.id;
      state.projects = state.projects.filter((project) => project.id !== projectId);
      state.loaded = true;
      state.processing = false;
      state.error = null;
    });

    builder.addCase(archiveProject.pending, (state: ProjectState) => {
      state.processing = true;
      state.loaded = false;
    });

    builder.addCase(archiveProject.rejected, (state: ProjectState, action: any) => {
      state.error = parseError(action);
      state.processing = false;
      state.loaded = false;
    });

    builder.addCase(archiveProject.fulfilled, (state: ProjectState, action: PayloadAction<any, any, { arg?: { id: number } }>) => {
      const projectId = action?.meta?.arg?.id;
      console.log('archiveProject ', action);
      state.projects = state.projects.filter((project) => project.id !== projectId);
      state.loaded = true;
      state.processing = false;
      state.error = null;
    });

    builder.addCase(restoreProject.pending, (state: ProjectState) => {
      state.processing = true;
      state.loaded = false;
    });

    builder.addCase(restoreProject.rejected, (state: ProjectState, action: any) => {
      state.error = parseError(action);
      state.processing = false;
      state.loaded = false;
    });

    builder.addCase(restoreProject.fulfilled, (state: ProjectState, action: PayloadAction<any, any, { arg?: { id: number } }>) => {
      const projectId = action?.meta?.arg?.id;
      state.projects = state.projects.filter((project) => project.id !== projectId);
      state.loaded = true;
      state.processing = false;
      state.error = null;
    });
  },
});

export const { reducer } = slice;

export const { resetCurrent } = slice.actions;

export default slice;
