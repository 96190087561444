import React, { forwardRef } from 'react';
import { Theme, Paper } from '@material-ui/core';
import { makeStyles, experimentalStyled } from '@material-ui/core/styles';
import { SnackbarContent } from 'notistack';
import { useSelector } from 'src/store';
import NotificationItem from './NotificationItem';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important',
    },
  },
  card: {
    backgroundColor: '#EBE1AF',
    width: '100%',
  },
  typography: {
    fontWeight: 'bold',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    marginLeft: 'auto',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  collapse: {
    padding: 16,
  },
  checkIcon: {
    fontSize: 20,
    color: '#b3b3b3',
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
}));

interface SnackMessageProps {
  id: string | number;
  message: string;
}

const Wraper = experimentalStyled(Paper)({
  background: '#c6e2e6',
  display: 'flex',
  padding: 16,
  zIndex: 10000,
  boxShadow: [
    '0 2.8px 2.2px rgba(0, 0, 0, 0.034)',
    '0 6.7px 5.3px rgba(0, 0, 0, 0.048)',
    '0 12.5px 10px rgba(0, 0, 0, 0.06)',
    '0 22.3px 17.9px rgba(0, 0, 0, 0.072)',
    '0 41.8px 33.4px rgba(0, 0, 0, 0.086)',
    '0 100px 80px rgba(0, 0, 0, 0.12)',
  ].join(' '),
  '& .MuiListItemSecondaryAction-root': {
    display: 'none',
  },
});

const SnackMessage = forwardRef<HTMLDivElement, SnackMessageProps>(({ id }: SnackMessageProps, ref) => {
  const classes = useStyles();

  const n = useSelector((state) => state.notification?.values?.filter(({ _id }: any) => id === _id));

  return (
    <SnackbarContent
      ref={ref}
      className={classes.root}
    >
      {n[0] && (
      <NotificationItem
        key={id}
        Wraper={Wraper}
        {...n[0]}
      />
      )}
    </SnackbarContent>
  );
});

export default SnackMessage;
