import { createSlice } from '@reduxjs/toolkit';
import { find, merge } from 'lodash';
import type { PayloadAction } from '@reduxjs/toolkit';
import { kanbanApi } from '../__fakeApi__/kanbanApi';
import deliverableApi from 'src/api/deliverableApi';
import type { AppThunk } from '../store';
import type { Attachment, Board, Card, CheckItem, Checklist, Column, Comment, Member } from '../types/kanban';
import objFromArray from '../utils/objFromArray';
import { jiraConfig } from 'src/config';
import type { SubtaskUpdatePayload, IssueUpdatePayload } from 'src/types/event';
import { JiraIssue } from 'src/types/issue';
import { jiraDocParser } from 'src/utils/text';
import { DeliverableCard, DeliverableSubTask } from 'src/types/deliverable';
import { issueApi } from 'src/api/issueApi';

interface KanbanState<T = DeliverableCard> {
  isLoaded: boolean;
  modalOpen: boolean;
  columns: {
    byId: Record<string, Column>;
    allIds: string[];
  };
  cards: {
    byId: Record<string, T>;
    allIds: string[];
  };
  members: {
    byId: Record<string, Member>;
    allIds: string[];
  };
}

const initialState: KanbanState = {
  isLoaded: false,
  modalOpen: false,
  columns: {
    byId: {},
    allIds: [],
  },
  cards: {
    byId: {},
    allIds: [],
  },
  members: {
    byId: {},
    allIds: [],
  },
};

const getLocalColumnFromJiraColumn = (id: string) => {
  const column = find(Object.values(jiraConfig.statusCategories), (col: any) => col.value.includes(parseInt(id, 10)));
  return !column ? false : column.id;
};

const getJiraColumnFromLocal = (id: string) => {
  const column = find(Object.values(jiraConfig.statusCategories), (col: any) => parseInt(id, 10) === col.id);
  return !column ? false : [...column.value].pop().toString();
};

/**
 * move a card from a column to another based on status
 *
 * @param state
 * @param param1
 * @returns
 */
const switchCardColumnFn = (state: KanbanState, { issueId, status }: { issueId: string; status: number }): void => {
  const cols: Record<string, Column> = state.columns?.byId;

  // guess current column where the issue is (source)
  const cardsFlat = Object.entries(cols)
    .map(([col, val]: [string, Column]) => val.cardIds.map((id: string) => ({
      id,
      col,
    })))
    .flat();

  const sourceIndex = cardsFlat.map((c) => c.id).indexOf(issueId);
  if (sourceIndex < 0) {
    return;
  }

  const source = cardsFlat[sourceIndex].col;

  // detrmine column where the issue should land (destination)
  const statusFlat = Object.entries(cols)
    .map(([col, val]: [string, Column]) => val.allowedValues.map((id: number) => ({
      id,
      col,
    })))
    .flat();
  const destinationIndex = statusFlat.map((c) => c.id).indexOf(status);
  if (destinationIndex < 0) {
    return;
  }

  const destination = statusFlat[destinationIndex].col;

  // if source === destination -> no action
  // otherwise: remove from source, then add to destination
  if (source !== destination) {
    // remove from source
    state.columns.byId[source].cardIds.splice(state.columns.byId[source].cardIds.indexOf(issueId), 1);

    // append to destination
    state.columns.byId[destination].cardIds.unshift(issueId);
  }
};

const slice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    toggleModal(state: KanbanState, action: PayloadAction<boolean>) {
      state.modalOpen = typeof action.payload === 'boolean' ? action.payload : !state.modalOpen;
    },
    getBoard(state: KanbanState, action: PayloadAction<Board>): void {
      const board = action.payload;

      state.columns.byId = objFromArray(board.columns);
      state.columns.allIds = Object.keys(state.columns.byId);
      state.cards.byId = objFromArray(board.cards);
      state.cards.allIds = Object.keys(state.cards.byId);
      state.members.byId = objFromArray(board.members);
      state.members.allIds = Object.keys(state.members.byId);
      state.isLoaded = true;
    },
    createColumn(state: KanbanState, action: PayloadAction<Column>): void {
      const column = action.payload;

      state.columns.byId[column.id] = column;
      state.columns.allIds.push(column.id);
    },
    updateColumn(state: KanbanState, action: PayloadAction<Column>): void {
      const column = action.payload;

      state.columns.byId[column.id] = column;
    },
    clearColumn(state: KanbanState, action: PayloadAction<string>): void {
      const columnId = action.payload;

      // cardIds to be removed
      const { cardIds } = state.columns.byId[columnId];

      // Delete the cardIds references from the column
      state.columns.byId[columnId].cardIds = [];

      // Delete the cards from state
      cardIds.forEach((cardId) => {
        delete state.cards.byId[cardId];
      });

      state.cards.allIds = state.cards.allIds.filter((cardId) => cardIds.includes(cardId));
    },
    deleteColumn(state: KanbanState, action: PayloadAction<string>): void {
      const columnId = action.payload;

      delete state.columns.byId[columnId];
      state.columns.allIds = state.columns.allIds.filter((_listId) => _listId !== columnId);
    },
    createCard(state: KanbanState, action: PayloadAction<Card>): void {
      const card = action.payload;

      state.cards.byId[card.id] = card;
      state.cards.allIds.push(card.id);

      // Add the cardId reference to the column
      state.columns.byId[card.columnId].cardIds.push(card.id);
    },
    updateCard(state: KanbanState, action: PayloadAction<Card>): void {
      const card = action.payload;

      Object.assign(state.cards.byId[card.id], card);
    },
    updateCardStatus(state: KanbanState, action: PayloadAction<{ cardId: string; statusId: string }>): void {
      const { cardId, statusId } = action.payload;
      state.cards.byId[cardId].status = statusId;
    },
    updateCardField(state: KanbanState, action: PayloadAction<{ issueId: string; data: Partial<Card>[] }>): void {
      const { issueId, data } = action.payload;
      state.cards.byId[issueId] = {
        ...state.cards.byId[issueId],
        ...data.reduce((r: Partial<Card>, partial: Partial<Card>) => merge(r, partial), {}),
      };
    },
    updatePartialCard(state: KanbanState, action: PayloadAction<IssueUpdatePayload>): void {
      const { issueId, data } = action.payload;
      state.cards.byId[issueId] = {
        ...state.cards.byId[issueId],
        ...data,
      };

      // switch only if modal is not open
      if (data.status && !state.modalOpen) {
        switchCardColumnFn(state, { issueId, status: data.status });
      }
    },
    addCardAttachments(state: KanbanState, action: PayloadAction<{ id: string; attachments: Attachment[] }>): void {
      state.cards.byId[action.payload.id].attachments = [
        ...(state.cards.byId[action.payload.id].attachments || []),
        ...action.payload.attachments,
      ];
    },
    removeCardAttachment(state: KanbanState, action: PayloadAction<{ id: string; attachmentId: number }>): void {
      state.cards.byId[action.payload.id].attachments = (state.cards.byId[action.payload.id].attachments || []).filter(
        (f: Attachment) => parseInt(f.id, 10) !== action.payload.attachmentId
      );
    },
    switchCardColumn(state: KanbanState, action: PayloadAction<{ issueId: string; status: number }>): void {
      switchCardColumnFn(state, action.payload);
    },
    updatePartialSubtask(state: KanbanState, action: PayloadAction<SubtaskUpdatePayload>): void {
      const { parentId, issueId, data } = action.payload;
      state.cards.byId[parentId].subtasks = state.cards.byId[parentId].subtasks.map((subtask: DeliverableSubTask) => {
        if (subtask.id === issueId) {
          return {
            ...subtask,
            ...data,
          };
        }

        return subtask;
      });
    },
    moveCard(
      state: KanbanState,
      action: PayloadAction<{
        cardId: string;
        position: number;
        columnId?: string;
      }>
    ): void {
      const { cardId, position, columnId } = action.payload;
      const sourceColumnId = state.cards.byId[cardId].columnId;
      const localSrcColumnId = getLocalColumnFromJiraColumn(sourceColumnId) || '0';

      // Remove card from source column
      state.columns.byId[localSrcColumnId].cardIds = state.columns.byId[localSrcColumnId].cardIds.filter(
        (_cardId) => _cardId !== cardId
      );

      // If columnId exists, it means that we have to add the card to the new column
      if (columnId) {
        // Change card's columnId reference
        state.cards.byId[cardId].columnId = getJiraColumnFromLocal(columnId) || '0';
        // Push the cardId to the specified position
        state.columns.byId[columnId].cardIds.splice(position, 0, cardId);
      } else {
        // Push the cardId to the specified position
        state.columns.byId[localSrcColumnId].cardIds.splice(position, 0, cardId);
      }
    },
    deleteCard(state: KanbanState, action: PayloadAction<string>): void {
      const cardId = action.payload;
      const { columnId } = state.cards.byId[cardId];

      delete state.cards.byId[cardId];
      state.cards.allIds = state.cards.allIds.filter((_cardId) => _cardId !== cardId);
      state.columns.byId[columnId].cardIds = state.columns.byId[columnId].cardIds.filter(
        (_cardId) => _cardId !== cardId
      );
    },
    addComment(state: KanbanState, action: PayloadAction<Comment>): void {
      const comment = action.payload;
      const card = state.cards.byId[comment.cardId];

      card.comments.push(comment);
    },
    addChecklist(state: KanbanState, action: PayloadAction<{ cardId: string; checklist: Checklist }>): void {
      const { cardId, checklist } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists.push(checklist);
    },
    updateChecklist(state: KanbanState, action: PayloadAction<{ cardId: string; checklist: Checklist }>): void {
      const { cardId, checklist } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = card.checklists.map((_checklist) => {
        if (_checklist.id === checklist.id) {
          return checklist;
        }

        return _checklist;
      });
    },
    deleteChecklist(state: KanbanState, action: PayloadAction<{ cardId: string; checklistId: string }>): void {
      const { cardId, checklistId } = action.payload;
      const card = state.cards.byId[cardId];

      card.checklists = card.checklists.filter((checklist) => checklist.id !== checklistId);
    },
    addCheckItem(
      state: KanbanState,
      action: PayloadAction<{
        cardId: string;
        checklistId: string;
        checkItem: CheckItem;
      }>
    ): void {
      const { cardId, checklistId, checkItem } = action.payload;
      const card = state.cards.byId[cardId];
      const checklist = card.checklists.find((_checklist) => _checklist.id === checklistId);

      checklist.checkItems.push(checkItem);
    },
    updateCheckItem(
      state: KanbanState,
      action: PayloadAction<{
        cardId: string;
        checklistId: string;
        checkItem: CheckItem;
      }>
    ): void {
      const { cardId, checklistId, checkItem } = action.payload;
      const card = state.cards.byId[cardId];
      const checklist = card.checklists.find((_checklist) => _checklist.id === checklistId);

      checklist.checkItems = checklist.checkItems.map((_checkItem) => {
        if (_checkItem.id === checkItem.id) {
          return checkItem;
        }

        return _checkItem;
      });
    },
    deleteCheckItem(
      state: KanbanState,
      action: PayloadAction<{
        cardId: string;
        checklistId: string;
        checkItemId: string;
      }>
    ): void {
      const { cardId, checklistId, checkItemId } = action.payload;
      const card = state.cards.byId[cardId];
      const checklist = card.checklists.find((_checklist) => _checklist.id === checklistId);

      checklist.checkItems = checklist.checkItems.filter((checkItem) => checkItem.id !== checkItemId);
    },
  },
});

export const { reducer } = slice;

const flattenPriority = (issue: JiraIssue): string => {
  switch (Number(issue?.fields?.issuetype?.id)) {
    case jiraConfig.issueType.DELIVERABLE.value:
      return issue?.fields?.customfield_10064?.value;
    case jiraConfig.issueType.BUG.value:
      return issue?.fields?.priority?.id;
    default:
      return '0';
  }
};

// we use this factor to orient the sense of the order according
// to issue type
const sortFactorByType = {
  [jiraConfig.issueType.DELIVERABLE.value]: 1,
  [jiraConfig.issueType.BUG.value]: 1,
};

/**
 * Reordering cards (all), in columns they should follow the sama order
 *
 * @param a
 * @param b
 * @returns
 */
const cardSorter = (a: DeliverableCard, b: DeliverableCard) => {
  if (a.priority === b.priority) {
    return 0;
  }

  // if same type -> just compare priority value which is already ordinated
  if (a.typeId === b.typeId) {
    return sortFactorByType[a.typeId] * Number(a.priority) < sortFactorByType[b.typeId] * Number(b.priority) ? -1 : 1;
  }

  if (Number(a.typeId) === jiraConfig.issueType.BUG.value) {
    return -1;
  }

  return 1;
};

export const transformSubtask = (issue: JiraIssue): DeliverableSubTask => ({
  id: `${issue.id}`,
  name: issue.fields.summary,
  description: issue.fields.description,
  status: Number(issue.fields.status.id),
  iconUrl: issue.fields.issuetype.iconUrl,
  issueType: Number(issue.fields.issuetype.id),
});

export const getBoard = (): AppThunk => async (dispatch, getState): Promise<void> => {
  const data = await deliverableApi.getBoard();
  let cards: DeliverableCard[] = data.cards.slice(0);

  if (getState().issue.type === 'deliverables') {
    const acceptanceCriteriaItems = {};
    const subtaskWorklogs: Record<string, JiraIssue['fields']['worklog'][]> = {};
    (getState().issue.issues || [])
      .filter((issue: JiraIssue) => issue.fields && issue.fields.parent)
      .forEach((issue: JiraIssue) => {
        if (!acceptanceCriteriaItems[issue.fields.parent.id]) {
          acceptanceCriteriaItems[issue.fields.parent.id] = {};
        }
        acceptanceCriteriaItems[issue.fields.parent.id][issue.id] = transformSubtask(issue);

        // register worklogs
        if (!subtaskWorklogs[issue.fields.parent.id]) {
          subtaskWorklogs[issue.fields.parent.id] = [];
        }
        subtaskWorklogs[issue.fields.parent.id].push(issue.fields.worklog);
      });

    cards = (getState().issue.issues || [])
      .filter(
        (issue: JiraIssue) => issue.fields
          && [
            jiraConfig.issueType.DELIVERABLE.value,
            jiraConfig.issueType.BUG.value
          ].includes(Number(issue.fields.issuetype.id))
          && issue.fields.status
      )
      .map((issue: JiraIssue) => ({
        id: `${issue.id}`,
        attachments: issue.fields?.attachment || [],
        checklists: [],
        subtasks: (acceptanceCriteriaItems[issue.id]
          ? Object.values(acceptanceCriteriaItems[issue.id]).map((v) => v)
          : []) as DeliverableSubTask[],
        comments: [],
        cover: '',
        due: null,
        isSubscribed: false,
        columnId: `${issue.fields.status.id}`,
        memberIds: [],
        name: issue.fields.summary,
        status: issue.fields.status.id,
        description: jiraDocParser(issue.fields.description),
        who: issue.fields.customfield_10066,
        what: jiraDocParser(issue.fields.customfield_10062),
        why: jiraDocParser(issue.fields.customfield_10063),
        priority: flattenPriority(issue),
        key: `${issue.key}`,
        iconUrl: `${issue.fields.issuetype.iconUrl}`,
        typeId: `${issue.fields.issuetype.id}`,
        // combine worklogs from main issue and its subtasks
        worklog: (subtaskWorklogs[issue.id] || []).reduce(
          (acc, worklogs) => ({
            total: acc.total + worklogs.total,
            worklogs: [...acc.worklogs, ...worklogs.worklogs],
          }),
          issue.fields.worklog || {
            total: 0,
            worklogs: [],
          }
        ),
      }))
      .sort(cardSorter);

    cards.forEach((card: DeliverableCard) => {
      data.columns.forEach((col: Column, index: number) => {
        if ((col.allowedValues || []).includes(parseInt(card.columnId, 10))) {
          data.columns[index].cardIds.push(card.id);
        }
      });
    });
  }

  dispatch(slice.actions.getBoard({ ...data, cards }));
};

export const createColumn = (name: string): AppThunk => async (dispatch): Promise<void> => {
  const data = await kanbanApi.createColumn({ name });

  dispatch(slice.actions.createColumn(data));
};

export const updateColumn = (columnId: string, update: any): AppThunk => async (dispatch): Promise<void> => {
  const data = await kanbanApi.updateColumn({ columnId, update });

  dispatch(slice.actions.updateColumn(data));
};

export const clearColumn = (columnId: string): AppThunk => async (dispatch): Promise<void> => {
  await kanbanApi.clearColumn(columnId);

  dispatch(slice.actions.clearColumn(columnId));
};

export const deleteColumn = (columnId: string): AppThunk => async (dispatch): Promise<void> => {
  await kanbanApi.deleteColumn(columnId);

  dispatch(slice.actions.deleteColumn(columnId));
};

export const createCard = (columnId: string, name: string): AppThunk => async (dispatch): Promise<void> => {
  const data = await kanbanApi.createCard({ columnId, name });

  dispatch(slice.actions.createCard(data));
};

export const updateCard = (cardId: string, update: any): AppThunk => async (dispatch): Promise<void> => {
  const data = await kanbanApi.updateCard({ cardId, update });

  dispatch(slice.actions.updateCard(data));
};

export const moveCard = (
  cardId: string,
  position: number,
  columnId?: string,
  formerPosition?: { position: number; columnId: string }
): AppThunk => async (dispatch, getState): Promise<void> => {
  const status = getJiraColumnFromLocal(columnId);
  const { project }: any = await getState();

  try {
    // Just move the card first
    dispatch(
      slice.actions.moveCard({
        cardId,
        position,
        columnId,
      })
    );

    // If no former position, it means it is no status change but just rearranging
    // - in the same column
    if (formerPosition) {
      const issueWithNewStatus: any = await issueApi.transitionToStatus(project.current.id, cardId, status);
      // Update current card status so it shows correct status on Kanban
      dispatch(
        slice.actions.updateCardStatus({
          cardId,
          statusId: issueWithNewStatus.fields.status.id,
        })
      );
    }
  } catch (e) {
    // Revert card moving when error
    if (formerPosition) {
      dispatch(
        slice.actions.moveCard({
          cardId,
          position: formerPosition.position,
          columnId: formerPosition.columnId,
        })
      );
    }

    throw e;
  }
};

export const deleteCard = (cardId: string): AppThunk => async (dispatch): Promise<void> => {
  await kanbanApi.deleteCard(cardId);

  dispatch(slice.actions.deleteCard(cardId));
};

export const addComment = (cardId: string, message: string): AppThunk => async (dispatch): Promise<void> => {
  const data = await kanbanApi.addComment({ cardId, message });

  dispatch(slice.actions.addComment(data));
};

export const addChecklist = (cardId: string, name: string): AppThunk => async (dispatch): Promise<void> => {
  const data = await kanbanApi.addChecklist({ cardId, name });

  dispatch(
    slice.actions.addChecklist({
      cardId,
      checklist: data,
    })
  );
};

export const updateChecklist = (cardId: string, checklistId: string, update: any): AppThunk => async (dispatch): Promise<void> => {
  const data = await kanbanApi.updateChecklist({
    cardId,
    checklistId,
    update,
  });

  dispatch(
    slice.actions.updateChecklist({
      cardId,
      checklist: data,
    })
  );
};

export const deleteChecklist = (cardId: string, checklistId: string): AppThunk => async (dispatch): Promise<void> => {
  await kanbanApi.deleteChecklist({ cardId, checklistId });

  dispatch(
    slice.actions.deleteChecklist({
      cardId,
      checklistId,
    })
  );
};

export const addCheckItem = (cardId: string, checklistId: string, name: string): AppThunk => async (dispatch): Promise<void> => {
  const data = await kanbanApi.addCheckItem({ cardId, checklistId, name });

  dispatch(
    slice.actions.addCheckItem({
      cardId,
      checklistId,
      checkItem: data,
    })
  );
};

export const updateCheckItem = (cardId: string, checklistId: string, checkItemId: string, update: any): AppThunk => async (dispatch): Promise<void> => {
  const data = await kanbanApi.updateCheckItem({
    cardId,
    checklistId,
    checkItemId,
    update,
  });

  dispatch(
    slice.actions.updateCheckItem({
      cardId,
      checklistId,
      checkItem: data,
    })
  );
};

export const deleteCheckItem = (cardId: string, checklistId: string, checkItemId: string): AppThunk => async (dispatch): Promise<void> => {
  await kanbanApi.deleteCheckItem({ cardId, checklistId, checkItemId });

  dispatch(
    slice.actions.deleteCheckItem({
      cardId,
      checklistId,
      checkItemId,
    })
  );
};

export const {
  updatePartialSubtask,
  updatePartialCard,
  updateCardField,
  addCardAttachments,
  removeCardAttachment,
  toggleModal,
  switchCardColumn,
} = slice.actions;

export default slice;
