import { axiosGraqhqlInstance } from 'src/api/axios';
import { storage as s } from '../utils/storage';
import { Sprint, SprintCollection, SprintInput, SprintIssuesCollection, SprintIssuesInput } from 'src/types/sprint';

export interface GetQueryOptions {
  full?: boolean;
  withSubtasks?: boolean;
}

class SprintAPI {
  axios: typeof axiosGraqhqlInstance;

  constructor(private userToken?: string) {
    this.axios = axiosGraqhqlInstance;
    if (userToken) {
      this.axios.defaults.headers.common.Authorization = `Bearer ${this.userToken}`;
    }
  }

  async createSprint(projectId: string, payload: SprintInput): Promise<any> {
    const response = await this.axios.post<{
      newSprint: Sprint;
    }>(
      'graphql',
      {
        query: `mutation NewSprint($inputs: SprintInput){
        newSprint(inputs: $inputs) {
          id
          name
          startDate
          endDate
          self
          state
          originBoardId
        }
      }`,
        variables: {
          inputs: {
            name: payload.name,
            startDate: payload.startDate,
          },
        },
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );

    return response.data.newSprint;
  }

  async getSprints(projectId: string): Promise<any> {
    const response = await this.axios.post<{
      getSprints: SprintCollection;
    }>(
      'graphql', {
        query: `query getSprints {
          getSprints {
            maxResults
            startAt
            values {
              id
              name
              startDate
              originBoardId
              state
              self
            }
          }
        }`,
        variables: {},
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );
    return response.data.getSprints;
  }

  async deleteSprint(projectId: string, sprintId: string): Promise<any> {
    const response = await this.axios.post<{
      deleteSprint: { id: number };
    }>(
      'graphql',
      {
        query: `mutation deleteSprint($id: String){
          deleteSprint(id: $id){
            id
        }
      }`,
        variables: {
          id: sprintId
        },
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );
    return response.data.deleteSprint;
  }

  async editSprint(projectId: string, payload: SprintInput): Promise<any> {
    const response = await this.axios.post<{
      editSprint: Sprint;
    }>(
      'graphql',
      {
        query: `mutation editSprint($inputs: SprintInput){
          editSprint(inputs: $inputs) {
            id
            name
            state
        }
      }`,
        variables: {
          inputs: {
            name: payload.name,
            id: payload.id.toString(),
            state: payload.state,
          },
        },
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );

    return response.data.editSprint;
  }

  // return the issueKey
  async addIssueSprint(projectId: string, payload: SprintIssuesInput): Promise<any> {
    const response = await this.axios.post<{
      addIssueSprint: { issueKey: string };
    }>(
      'graphql',
      {
        query: `mutation addIssueSprint($inputs: SprintIssuesInput){
          addIssueSprint(inputs: $inputs) {
            issueKey
        }
      }`,
        variables: {
          inputs: {
            sprintId: payload.sprintId,
            issueKey: payload.issueKey,
          },
        },
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );

    return response.data.addIssueSprint;
  }

  async addIssueBacklog(projectId: string, payload: SprintIssuesInput): Promise<any> {
    const response = await this.axios.post<{
      addIssueBacklog: { issueKey: string };
    }>(
      'graphql',
      {
        query: `mutation addIssueBacklog($inputs: SprintIssuesInput){
          addIssueBacklog(inputs: $inputs) {
            issueKey
        }
      }`,
        variables: {
          inputs: {
            sprintId: payload.sprintId,
            issueKey: payload.issueKey,
          },
        },
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );

    return response.data.addIssueBacklog;
  }

  async fetchIssuesSprint(projectId: string, sprintId: string): Promise<any> {
    const response = await this.axios.post<{
      fetchIssuesSprint: SprintIssuesCollection;
    }>(
      'graphql',
      {
        query: `query fetchIssuesSprint($id: String) {
          fetchIssuesSprint (id: $id) {
            maxResults
            total
            startAt
            issues {
              self
              id
              key
              fields {
                summary
                issuetype {
                  id
                  name
                  iconUrl
                }
              }
            }
        }
      }`,
        variables: {
          id: sprintId
        },
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );
    return response.data.fetchIssuesSprint;
  }

  async fetchBoardBacklog(projectId: string): Promise<any> {
    const response = await this.axios.post<{
      fetchBoardBacklog: SprintIssuesCollection;
    }>(
      'graphql',
      {
        query: `query fetchBoardBacklog {
          fetchBoardBacklog {
            maxResults
            total
            startAt
            issues {
              self
              id
              key
              fields {
                summary
                issuetype {
                  id
                  name
                  iconUrl
                }
              }
            }
        }
      }`,
        variables: {},
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );

    return response.data.fetchBoardBacklog;
  }
}

export const sprintAPI = new SprintAPI(s.recursive('user.token'));
