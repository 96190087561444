import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import type { AppThunk } from '../store';
import type { PayloadAction } from '@reduxjs/toolkit';
import { issueApi, GetQueryOptions } from '../api/issueApi';
import type { IssueQuery, JiraIssue as Issue, BasicIssueInput } from '../types/issue';
import { fileApi, UPLOAD_DESTINATION } from '../api/fileApi';
import { DeliverableInput } from 'src/types/deliverable';
import { RequestInput } from 'src/types/request';
import { parseError } from 'src/utils/error';
import { updatePartialCard, updatePartialSubtask, addCardAttachments, removeCardAttachment } from './kanban';
import type { SubtaskUpdatePayload, IssueUpdatePayload } from 'src/types/event';
import { FileType } from 'src/types/file';
import { Attachment } from 'src/types/kanban';

interface Paginate {
  total: number;
  maxResults: number;
  startAt: number;
  currentPage?: number;
}

interface IssueState {
  issues: Issue[];
  type: string;
  current: Issue | null;
  processing: boolean;
  processingUpdate: boolean;
  loaded: boolean;
  error: Error | null;
  paginate: Paginate;
  fileDeleteProcessing: string | null;
}

const initialState: IssueState = {
  issues: [],
  type: '',
  current: null,
  processing: false,
  processingUpdate: false,
  loaded: false,
  error: null,
  paginate: {
    startAt: 0,
    total: 0,
    maxResults: 8,
    currentPage: 1,
  },
  fileDeleteProcessing: null,
};

export const createDeliverableIssue = createAsyncThunk<Issue, { projectId: string } & DeliverableInput>(
  'issue/createDeliverable',
  async ({ projectId, ...createData }, { rejectWithValue }) => {
    try {
      return await issueApi.createDeliverable(projectId, createData);
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const createRequestIssue = createAsyncThunk<Issue, { projectId: string } & RequestInput>(
  'issue/createRequest',
  async ({ projectId, uploadFiles, ...createData }, { rejectWithValue }) => {
    try {
      const request = await issueApi.createRequest(projectId, createData);
      const hasFiles = uploadFiles && Array.isArray(uploadFiles) && uploadFiles.length > 0;

      if (hasFiles) {
        await fileApi.upload(uploadFiles, UPLOAD_DESTINATION.JIRA_ISSUE, request.id);
      }

      return request;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const createBugIssue = createAsyncThunk<Issue, { projectId: string } & BasicIssueInput>(
  'issue/createBug',
  async ({ projectId, uploadFiles, ...data }, { rejectWithValue }) => {
    try {
      const issue = await issueApi.createBug(projectId, data);
      const hasFiles = uploadFiles && Array.isArray(uploadFiles) && uploadFiles.length > 0;

      if (hasFiles) {
        await fileApi.upload(uploadFiles, UPLOAD_DESTINATION.JIRA_ISSUE, String(issue.id));
      }

      return issue;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const updateIssue = createAsyncThunk<
Issue,
{ projectId: string; issueId: string } & RequestInput & DeliverableInput
>('issue/update', async ({ projectId, issueId, uploadFiles, ...updateData }, { rejectWithValue }) => {
  try {
    // Handle File upload first
    if (uploadFiles && Array.isArray(uploadFiles) && uploadFiles.length > 0) {
      await fileApi.upload(uploadFiles, UPLOAD_DESTINATION.JIRA_ISSUE, issueId);
    }

    const request = await issueApi.update(projectId, issueId, updateData);

    return request;
  } catch (e) {
    if (!e.response) {
      throw e;
    }

    return rejectWithValue(e.response);
  }
});

export const fetchIssues = createAsyncThunk<{ issues: Issue[] } & Paginate, { projectId: string } & IssueQuery>(
  'issue/fetch',
  // @ts-ignore
  async ({ projectId, ...query }, { rejectWithValue, dispatch }) => {
    try {
      const { startAt, maxResults, total, ...result } = await issueApi.search(projectId, { ...query });

      if (startAt + maxResults < total) {
        dispatch(fetchIssues({ projectId, ...query, startAt: (startAt || 0) + maxResults }));
      }

      return { startAt, maxResults, total, ...result };
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const getIssue = createAsyncThunk<Issue, { id: string; projectId: string; opts?: GetQueryOptions }>(
  'issue/get',
  async ({ id, projectId, opts }, { rejectWithValue }) => {
    try {
      return await issueApi.get(projectId, id, opts);
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const deleteFileIssue = createAsyncThunk<any, { id: string }>(
  'issue/file/delete',
  async ({ id }, { rejectWithValue, getState }) => {
    try {
      const {
        // @ts-ignore
        issue: { current },
      } = getState();
      // Pass second parameter to True to indicate that id is actually a JIRA id
      // and not a DB id
      const results = await fileApi.delete({ id, location: UPLOAD_DESTINATION.JIRA_ISSUE, refId: current?.id }, true);

      return results.data;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const issueTransition = createAsyncThunk<Issue, { id: string; statusId: string }>(
  'issue/transition',
  async ({ id, statusId }, { rejectWithValue, getState }) => {
    try {
      const { project }: any = getState();
      const issue = await issueApi.transitionToStatus(project.current.id, id, statusId);

      return issue;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

const initProcess = (state: IssueState) => ({
  ...state,
  loaded: false,
  error: null,
  processing: true,
});
const updateIssueFields = (state: IssueState, action: PayloadAction<IssueUpdatePayload>) => {
  const { issueId, raw } = action.payload;
  state.issues = state.issues.map((issue: Issue) => {
    if (parseInt(issueId, 10) === issue.id) {
      return {
        ...issue,
        fields: {
          ...issue.fields,
          ...(raw || {}),
        },
      };
    }

    return issue;
  });
};

const slice = createSlice({
  name: 'issue',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    setError(state: IssueState, action: PayloadAction<Error>) {
      state.error = action.payload;
    },
    setCurrent(state: IssueState | null, action: PayloadAction<Issue>) {
      state.current = action.payload;
      state.error = null;
    },
    setCurrentIssueType(state: IssueState, action: PayloadAction<string>) {
      state.type = action.payload;
    },
    setCurrentIssues(state: IssueState, action: PayloadAction<Issue[]>) {
      state.issues = action.payload;
    },
    setCurrentPage(state: IssueState, action: PayloadAction<number>) {
      state.paginate.currentPage = action.payload;
    },
    addFileAttachments(state: IssueState, action: PayloadAction<{ id: number; files: FileType<Attachment>[] }>) {
      state.issues = state.issues.map((issue: Issue) => {
        if (issue.id === action.payload.id) {
          return {
            ...issue,
            fields: {
              ...issue.fields,
              attachment: [
                ...(issue.fields?.attachment || []),
                ...action.payload.files.map((file: FileType<Attachment>) => file.metadatas),
              ],
            },
          };
        }

        return issue;
      });

      if (state.current?.id === action.payload.id) {
        if (!state.current.fields.attachment) {
          state.current.fields.attachment = [];
        }

        state.current.fields.attachment.push(
          ...action.payload.files.map((file: FileType<Attachment>) => file.metadatas)
        );
      }
    },
    updateSubtaskData: (state: IssueState, action: PayloadAction<SubtaskUpdatePayload>) => {
      state.current.fields.subtasks = state.current?.fields?.subtasks.map(
        (subtask: Issue) => {
          if (Number(subtask.id) === Number(action.payload.issueId)) {
            return {
              ...subtask,
              fields: {
                ...subtask.fields,
                ...action.payload.raw
              }
            };
          }

          return subtask;
        }
      );
    },
    updateIssueData: (state: IssueState, action: PayloadAction<IssueUpdatePayload>) => {
      state.current.fields = {
        ...state.current.fields,
        ...action.payload.raw,
      };
    },
    removeFileAttachment(state: IssueState, action: PayloadAction<{ id: number; attachmentId: number }>) {
      state.issues = state.issues.map((issue: Issue) => {
        if (issue.id === action.payload.id) {
          return {
            ...issue,
            fields: {
              ...issue.fields,
              attachment: (issue.fields?.attachment || []).filter(
                (f: Attachment) => parseInt(f.id, 10) !== action.payload.attachmentId
              ),
            },
          };
        }

        return issue;
      });

      if (state.current?.id === action.payload.id) {
        state.current.fields.attachment = (state.current.fields?.attachment || []).filter(
          (f: Attachment) => parseInt(f.id, 10) !== action.payload.attachmentId
        );
      }
    },
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  extraReducers: (builder) => {
    // create deliverable
    builder.addCase(createDeliverableIssue.pending, initProcess);
    builder.addCase(createDeliverableIssue.rejected, (state: IssueState, action: any) => {
      state.error = parseError(action);
      state.loaded = false;
      state.processing = false;
    });

    builder.addCase(createDeliverableIssue.fulfilled, (state: IssueState, action: PayloadAction<Issue>) => {
      state.issues.push(action.payload);
      state.current = action.payload;
      state.type = 'deliverables';
      state.loaded = true;
      state.processing = false;
    });

    builder.addCase(createBugIssue.pending, initProcess);
    builder.addCase(createBugIssue.rejected, (state: IssueState, action: any) => {
      state.error = parseError(action);
      state.loaded = false;
      state.processing = false;
    });
    builder.addCase(createBugIssue.fulfilled, (state: IssueState, action: PayloadAction<Issue>) => {
      state.issues.push(action.payload);
      state.current = action.payload;
      state.type = 'deliverables';
      state.loaded = true;
      state.processing = false;
    });

    builder.addCase(createRequestIssue.pending, initProcess);
    builder.addCase(createRequestIssue.rejected, (state: IssueState, action: any) => {
      state.error = parseError(action);
      state.loaded = false;
      state.processing = false;
    });

    builder.addCase(createRequestIssue.fulfilled, (state: IssueState, action: PayloadAction<Issue>) => {
      state.issues.push(action.payload);
      state.current = action.payload;
      state.type = 'deliverables';
      state.loaded = true;
      state.processing = false;
    });

    // search issues
    builder.addCase(fetchIssues.pending, initProcess);
    builder.addCase(fetchIssues.rejected, (state: IssueState, action: any) => {
      state.error = parseError(action);
      state.loaded = false;
      state.processing = false;
    });

    builder.addCase(
      fetchIssues.fulfilled,
      (state: IssueState, action: PayloadAction<{ issues: Issue[] } & Paginate, string, { arg: IssueQuery }>) => {
        const { issues, ...paginate } = action.payload;

        const currentPage = 1 + Math.ceil((action.meta?.arg?.startAt || 0) / (action.meta?.arg?.maxResults || 100));

        if (currentPage === 1) {
          // generally means new issues set
          state.issues = issues;
        } else if (currentPage !== state.paginate.currentPage) {
          // append issues, when current page is not the same as the previous one
          state.issues = [...state.issues, ...issues];
        }

        state.paginate = {
          currentPage,
          maxResults: paginate.maxResults,
          startAt: paginate.startAt,
          total: paginate.total,
        };

        // don't change .loaded/.processing until all data set is loaded
        state.loaded = state.loaded || paginate.total === state.issues.length;
        state.processing = paginate.total > state.issues.length;

        console.log('issues', [currentPage, state.issues.length, state.paginate.total], { action });
      }
    );

    // get issue
    builder.addCase(getIssue.pending, initProcess);
    builder.addCase(getIssue.rejected, (state: IssueState, action: any) => {
      state.error = parseError(action);
      state.loaded = false;
      state.processing = false;
    });

    builder.addCase(getIssue.fulfilled, (state: IssueState, action: PayloadAction<Issue>) => {
      state.current = action.payload;
      state.loaded = true;
      state.processing = false;
    });

    // update issues
    builder.addCase(updateIssue.pending, (state) => ({
      ...state,
      processingUpdate: true,
    }));

    builder.addCase(updateIssue.rejected, (state: IssueState, action: any) => {
      state.error = parseError(action);
      state.loaded = false;
      state.processingUpdate = false;
    });

    builder.addCase(updateIssue.fulfilled, (state: IssueState, action: PayloadAction<Issue>) => {
      state.loaded = true;
      state.processingUpdate = true;
      state.current = merge(state.current || {}, action.payload);

      // update list
      state.issues = state.issues.map((issue: Issue) => {
        if (issue.id === action.payload.id) {
          return merge(issue, action.payload);
        }

        return issue;
      });
    });

    // delete file
    builder.addCase(deleteFileIssue.pending, (state: IssueState, action: any) => {
      state.fileDeleteProcessing = action?.meta?.arg?.id || null;
    });
    builder.addCase(deleteFileIssue.rejected, (state: IssueState, action: any) => {
      state.error = parseError(action);
      state.fileDeleteProcessing = null;
    });
    builder.addCase(deleteFileIssue.fulfilled, (state: IssueState) => {
      state.fileDeleteProcessing = null;
    });

    // side effect of kanban reducer
    // @ts-ignore
    builder.addCase(updatePartialCard.type, updateIssueFields);
    // @ts-ignore
    builder.addCase(updatePartialSubtask.type, updateIssueFields);
  },
});

export const { reducer } = slice;

export const { setCurrent, setCurrentIssueType, setCurrentPage, updateIssueData, updateSubtaskData, setCurrentIssues } = slice.actions;

// ATTENTION: only deliverable has card/kanban feature support
export const addFileAttachments = ({ id, files }: { id: number; files: FileType<Attachment>[] }): AppThunk => (dispatch, getState) => {
  dispatch(slice.actions.addFileAttachments({ id, files }));
  if (['deliverables'].includes(getState().issue.type)) {
    dispatch(
      addCardAttachments({
        id: id.toString(),
        attachments: files.map((f: FileType<Attachment>) => f.metadatas),
      })
    );
  }
};

export const removeFileAttachment = ({ id, attachmentId }: { id: number; attachmentId: number }): AppThunk => (dispatch, getState) => {
  dispatch(slice.actions.removeFileAttachment({ id, attachmentId }));
  if (['deliverables'].includes(getState().issue.type)) {
    dispatch(removeCardAttachment({ id: id.toString(), attachmentId }));
  }
};

export default slice;
