import React from 'react';
import { Grow } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { NotificationSnacked } from 'src/components/dashboard/notification';

export default ({ children }: React.PropsWithChildren<any>) => (
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    // @ts-ignore
    TransitionComponent={Grow}
    content={(key, message) => (
      <NotificationSnacked
        id={key}
        message={message as string}
      />
    )}
  >
    {children}
  </SnackbarProvider>
);
