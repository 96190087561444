import type { ElementType } from 'react';
import ExposurePlus1Icon from '@material-ui/icons/PlusOne';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import ChatAltIcon from 'src/icons/ChatAlt';
import { NotificationType, Notification } from 'src/types/notification';
import { jiraConfig } from 'src/config';

export const buildIssueLink = ({ projectId, issueId, issueType }): string => {
  if (!projectId) {
    return '#';
  }

  const issueTypeSlug = (Object.entries(jiraConfig.issueType).map(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ([_, v]) => v
  ).find(
    ({ value }) => value === Number(issueType)
  ) as { slug?: string })?.slug;

  return `/dashboard/projects/${projectId}/${issueTypeSlug || 'deliverables'}/${issueId}`;
};

export const iconsMap: Record<NotificationType, ElementType> = {
  [NotificationType.DELIVERABLE_DONE]: SystemUpdateIcon,
  [NotificationType.DELIVERABLE_ONHOLD]: SystemUpdateIcon,
  [NotificationType.DELIVERABLE_REVIEW]: SystemUpdateIcon,
  [NotificationType.COMMENT_NEW]: ChatAltIcon,
  [NotificationType.ESTIMATE_UPDATED]: HeadsetMicIcon,
  [NotificationType.PROJECT_INVITE]: ExposurePlus1Icon,
};

export declare type ClickAction = [string, any[]];

export const notificationAgg = (
  n: Notification
): {
  title: string;
  subText?: string;
  subtitle?: string | JSX.Element | JSX.Element[];
  avatar?: string;
  clickAction?: ClickAction;
} => {
  let title: string;
  // eslint-disable-next-line default-case
  switch (n.type) {
    case NotificationType.COMMENT_NEW:
      title = `${n.user?.displayName} left comment`;
      break;
    case NotificationType.DELIVERABLE_DONE:
      title = 'Deliverable is done';
      break;
    case NotificationType.DELIVERABLE_REVIEW:
      title = 'Deliverable awaiting for review';
      break;
    case NotificationType.DELIVERABLE_ONHOLD:
      title = `${n.user?.displayName} put deliverable on hold`;
      break;
    case NotificationType.ESTIMATE_UPDATED:
      title = `${n.user?.displayName} updated estimate`;
      break;
    case NotificationType.PROJECT_INVITE:
      title = `${n.user?.displayName} invited you to join a project`;
      break;
  }

  switch (n.type) {
    case NotificationType.COMMENT_NEW:
    case NotificationType.DELIVERABLE_DONE:
    case NotificationType.DELIVERABLE_REVIEW:
    case NotificationType.DELIVERABLE_ONHOLD:
    case NotificationType.ESTIMATE_UPDATED:
      return {
        title,
        avatar: n.user?.avatar,
        subText: [n.issue?.key, n.issue?.fields?.summary].join(' - '),
        // clickAction: ['issue.open', [0, n?.issue?.fields?.issuetype?.id, n?.issue?.id]],
        clickAction: ['issue.open', [n?.data?.projectId, n?.issue?.fields?.issuetype?.id, n?.issue?.id]],
        subtitle: (
          <>
            <span
              style={{
                fontSize: 12,
                display: 'flex',
                justifyContent: 'flex-start',
              }}
            >
              <img
                alt={n.issue?.key || ''}
                src={n.issue?.fields?.issuetype?.iconUrl || ''}
                style={{ marginRight: 3 }}
              />
              <span>
                {n.issue?.key}
                {' '}
                <span style={{ fontStyle: 'italic', marginLeft: 4 }}>{n.issue?.fields?.summary}</span>
              </span>
            </span>
          </>
        ),
      };
    case NotificationType.PROJECT_INVITE:
      return {
        title,
        avatar: n.user?.avatar || null,
        clickAction: ['location.href', [n?.data?.link]],
      };
    default:
      return {
        title,
        avatar: n.user?.avatar || null,
      };
  }
};
