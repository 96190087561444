import { get, pick } from 'lodash';

export const parseError = (action: any) => {
  if (action?.payload?.status) {
    const error = get(action?.payload, 'data.data.err', {});

    if (Object.entries(error).length > 0) {
      return error;
    }

    pick(action?.payload?.data, ['type', 'message', 'code', 'name']);
  }

  return action.error;
};
