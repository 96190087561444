import type { FC } from 'react';
import type { Theme } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { SxProps } from '@material-ui/system';

interface LogoProps {
  sx?: SxProps<Theme>;
}

const LogoRoot = experimentalStyled('svg')``;

const Logo: FC<LogoProps> = (props) => (
  <LogoRoot
    width="300.064"
    height="175.609"
    version="1.1"
    viewBox="94.347 168.891 300.064 175.609"
    {...props}
  >
    <g>
      <path
        style={{ fill: 'rgb(78, 136, 155)' }}
        d="M 177.443 170.505 C 177.443 170.505 241.666 271.961 273.313 322.85 C 286.721 344.409 308.228 354.933 323.049 321.774 C 345.931 270.583 394.411 168.891 394.411 168.891 L 357.613 168.891 C 357.613 168.891 305.856 282.954 298.322 297.012 C 295.768 301.778 216.908 170.269 216.908 170.269 L 177.443 170.505 Z"
      />
      <path
        style={{ fill: 'rgb(85, 167, 171)' }}
        d="M 94.347 171.615 C 94.347 171.615 157.983 273.227 190.216 323.96 C 204.094 345.803 219.715 356.815 239.952 322.883 C 245.714 313.223 249.156 308.744 249.156 308.744 C 249.156 308.744 230.535 279.318 230.531 279.315 C 230.531 279.315 223.866 289.196 216.333 301.386 C 213.438 306.071 133.089 171.615 133.089 171.615 L 94.347 171.615 Z"
      />
    </g>
  </LogoRoot>
);

export default Logo;
