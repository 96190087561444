import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useAuth from '../../hooks/useAuth';
import BriefcaseIcon from '../../icons/Briefcase';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import UserIcon from '../../icons/User';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { resolveFileUrl } from 'src/utils/file';
import { useDispatch, useSelector } from 'src/store';
import PushPinIcon from '@material-ui/icons/PushPin';
import { fetchUserPinnedProject, unPinUserProject } from 'src/slices/pinnedProjects';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const { user } = useAuth();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const { pinnedProjects } = useSelector((state) => ({
    pinnedProjects: state.pinnedProjects,
  }));
  const dispatch = useDispatch();

  const sections = [
    {
      title: '',
      items: [
        {
          title: 'Overview',
          path: '/dashboard',
          icon: <ChartSquareBarIcon fontSize="small" />,
        },
        {
          title: 'Projects',
          icon: <BriefcaseIcon fontSize="small" />,
          children: [
            {
              title: 'projectPinner',
            },
            ...pinnedProjects.projects
              .filter((item) => item.isPinned)
              .map((item) => ({
                title: item.projectName,
                icon: (
                  <>
                    {!pinnedProjects.status.includes(`loading:${item.projectId}`) ? (
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          dispatch(unPinUserProject(item.projectId));
                          e.stopPropagation();
                          e.preventDefault();
                        }}
                      >
                        <PushPinIcon
                          sx={{ fontSize: 'large' }}
                          className="PushPinIcon"
                        />
                      </IconButton>
                    ) : (
                      <IconButton size="small">
                        <CircularProgress size={18} />
                      </IconButton>
                    )}
                  </>
                ),
                iconEnd: true,
                path: `/dashboard/projects/${item.projectId}/overview`,
              })),
            {
              title: 'Browse All',
              path: '/dashboard/projects/browse',
            },
            {
              title: 'Create',
              path: '/dashboard/projects/new',
            },
            {
              title: 'Archived',
              path: '/dashboard/projects/archived',
            },
          ],
        },
        {
          title: 'Account',
          path: '/account',
          icon: <UserIcon fontSize="small" />,
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch(fetchUserPinnedProject());
  }, []);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 64,
                width: 64,
              }}
            />
          </RouterLink>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              overflow: 'hidden',
              p: 2,
            }}
          >
            <RouterLink to="/account">
              <Avatar
                src={resolveFileUrl(user.avatar)}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48,
                }}
              />
            </RouterLink>
            <Box sx={{ ml: 2 }}>
              <Typography
                color="textPrimary"
                variant="subtitle2"
              >
                {[user.firstName, user.lastName].join(' ')}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3,
                },
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default DashboardSidebar;
