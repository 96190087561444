import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { chain } from 'lodash';
import { projectApi } from '../api/projectApi';
import { issueApi } from '../api/issueApi';
import { parseError } from 'src/utils/error';
import { jiraConfig } from 'src/config';
import type { LoadableState } from 'src/types/common';
import type {
  LatestUpdatesResultMeta,
  LatestUpdatesResult,
  LatestUpdatesResultItem,
  ActivityLog,
  Changelog,
  ChangeDetails,
} from 'src/types/project';

interface ActivityState extends LoadableState<ActivityLog[]> {
  meta: LatestUpdatesResultMeta | null;
  issueCount: Record<string, number>;
}

const initialState: ActivityState = {
  values: [],
  processing: false,
  loaded: false,
  error: null,
  meta: {
    startAt: 0,
  },
  issueCount: {},
};

export const countProjectIssues = createAsyncThunk<Record<string, number>, { projectKey: string | number }>(
  'activity/issueCount',
  async ({ projectKey }, { rejectWithValue }) => {
    try {
      // count deliverables and requests
      const [deliverables, requests] = await Promise.all([
        issueApi.countByType(projectKey, jiraConfig.issueType.DELIVERABLE.value),
        issueApi.countByType(
          projectKey,
          jiraConfig.issueType.REQUEST_ESTIMATE.value,
          jiraConfig.issueType.REQUEST_REPORT.value,
          jiraConfig.issueType.REQUEST_APPT.value
        ),
      ]);

      return { deliverables, requests };
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const fetchProjectLatestUpdates = createAsyncThunk<
LatestUpdatesResult,
{ projectKey: string | number; startAt: number }
>('activity/fetch', async ({ projectKey, startAt }, { rejectWithValue, dispatch }) => {
  try {
    const latestUpdtes = await projectApi.fetchLatestUpdates(projectKey, startAt || 0);

    await dispatch(countProjectIssues({ projectKey }));

    return latestUpdtes;
  } catch (e) {
    if (!e.response) {
      throw e;
    }

    return rejectWithValue(e.response);
  }
});

const slice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setError(state: ActivityState, action: PayloadAction<Error>) {
      state.error = action.payload;
    },
    clearState() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      countProjectIssues.fulfilled,
      (state: ActivityState, action: PayloadAction<Record<string, number>>) => {
        state.issueCount = action.payload;
      }
    );

    builder.addCase(fetchProjectLatestUpdates.pending, (state) => ({
      ...state,
      loaded: false,
      processing: true,
    }));

    builder.addCase(fetchProjectLatestUpdates.rejected, (state: ActivityState, action: any) => {
      state.error = parseError(action);
      state.processing = false;
      state.loaded = false;
    });

    builder.addCase(
      fetchProjectLatestUpdates.fulfilled,
      (state: ActivityState, action: PayloadAction<LatestUpdatesResult>) => {
        state.processing = false;
        state.loaded = true;
        state.error = null;
        state.meta = action.payload?.meta?.issues?.jql;

        const activities: ActivityLog[] = [];

        // format data and populate changelog items with issue details
        // then sort by created date
        activities.push(
          ...(chain(action.payload.value)
            .map(({ changelogs, id, ...l }: LatestUpdatesResultItem) => changelogs.map((c: Changelog) => ({
              ...l,
              issueId: id,
              ...c,
            })))
            .flatten()
            // remove unecessary activity log
            .mapValues(({ items, ...c }: Changelog) => ({
              ...c,
              eventType: 'update',
              items: items.filter(
                (i: ChangeDetails) => !['resolution', 'IssueParentAssociation', 'Parent'].includes(i.field)
              ),
            }))
            .filter(({ items }: Changelog) => items.length > 0)
            .value() as ActivityLog[])
        );

        // add issue event logs now, issue creation especially
        activities.push(
          ...(chain(action.payload.value)
            .map(({ changelogs, id, ...l }: LatestUpdatesResultItem) => ({
              issueId: id,
              ...l,
              eventType: 'create',
            }))
            .value() as ActivityLog[])
        );

        state.values = chain(activities)
          .orderBy(({ created }: ActivityLog) => new Date(created), 'desc')
          .value();
      }
    );
  },
});

export const { reducer } = slice;

export default slice;
