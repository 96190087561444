import { envConfig } from 'src/config';

export const resolveFileUrl = (id: string) => `${envConfig.restUrl}/files/${id}`;

export const getFileUrl = (path) => {
  if (path && (path || '').match(/^\/files/)) {
    path = `${process.env.REACT_APP_BACKEND_URL}${path}`;
  }

  return path;
};

export const determineImage = (file) => {
  const ext = ((file || {}).name || '').split('.').pop();

  switch (ext) {
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'png':
    case 'bmp':
      return getFileUrl(file.link);
    case 'pdf':
      return '/static/images/extension/pdf.png';
    case 'doc':
      return '/static/images/extension/doc.png';
    case 'docx':
      return '/static/images/extension/doc.png';
    case 'xls':
      return '/static/images/extension/xls.png';
    case 'xlsx':
      return '/static/images/extension/xls.png';
    case 'ppt':
      return '/static/images/extension/ppt.png';
    case 'pptx':
      return '/static/images/extension/ppt.png';
    case 'zip':
      return '/static/images/extension/zip.png';
    case 'ai':
      return '/static/images/extension/ai.png';
    case 'svg':
      return '/static/images/extension/svg.png';
    case 'mov':
      return '/static/images/extension/mov.png';
    case 'psd':
      return '/static/images/extension/psd.png';
    case 'ps':
      return '/static/images/extension/ps.png';
    case 'aac':
      return '/static/images/extension/aac.png';
    // case 'mp3': return '/static/images/extension/mp3.png';
    case 'avi':
      return '/static/images/extension/avi.png';
    case 'eps':
      return '/static/images/extension/eps.png';
    case 'mpg':
      return '/static/images/extension/mpg.png';
    case 'mpeg':
      return '/static/images/extension/mpg.png';
    case 'flv':
      return '/static/images/extension/flv.png';
    case 'txt':
      return '/static/images/extension/txt.png';
    default:
      return '/static/images/extension/default.svg';
  }
};
