import { combineReducers } from '@reduxjs/toolkit';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as projectReducer } from 'src/slices/project';
import { reducer as issueReducer } from 'src/slices/issue';
import { reducer as activityReducer } from 'src/slices/activities';
import { reducer as settingsReducer } from 'src/slices/settings';
import { reducer as commentReducer } from 'src/slices/comments';
import { reducer as notificationReducer } from 'src/slices/notifications';
import { reducer as pinnedProjectsReducer } from 'src/slices/pinnedProjects';
import { reducer as sprintReducer } from 'src/slices/sprint';

const rootReducer = combineReducers({
  kanban: kanbanReducer,
  project: projectReducer,
  issue: issueReducer,
  activity: activityReducer,
  comment: commentReducer,
  notification: notificationReducer,
  settings: settingsReducer,
  pinnedProjects: pinnedProjectsReducer,
  sprint: sprintReducer
});

export default rootReducer;
