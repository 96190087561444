/* eslint-disable prefer-const */
import TurndownService from 'turndown';
import j2m from 'jira2md';

const service = new TurndownService({
  emDelimiter: '_',
  strongDelimiter: '*' as '__' | '**' | undefined,
});

const format = (text: string, how: string): string => {
  // parse how we should format
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let [source, s, destination]: string[] = how.split(/( ?:|-> ?)/g);
  if (!destination || !source) {
    console.warn(`unable to parse format request, should be [source -> destination], got "${how}"`);
    return text;
  }

  source = source.trim();
  destination = destination.trim();

  // only wiki, html, markdown are supported
  const supportedFormats = ['wiki', 'html', 'markdown'];
  if (!supportedFormats.includes(source)) {
    console.warn(`source format "${source}" is not supported`);
    return text;
  }

  if (!supportedFormats.includes(destination)) {
    console.warn(`destination format "${destination}" is not supported`);
    return text;
  }

  // if source is same as destination
  if (source === destination) {
    return text;
  }

  let fn = (o: string): string => o;

  console.log({ source, destination });

  // eslint-disable-next-line default-case
  switch (source) {
    case 'html':
      if (destination === 'wiki') {
        fn = (htmlValue: string) => j2m.to_jira(
          // convert from HTML to markdown first
          service.turndown(htmlValue)
        );
      } else if (destination === 'markdown') {
        fn = (htmlValue: string) => service.turndown(htmlValue);
      }
      break;
    case 'wiki':
      if (destination === 'html') {
        fn = j2m.jira_to_html.bind(j2m);
      } else if (destination === 'markdown') {
        fn = j2m.to_markdown.bind(j2m);
      }
      break;
    case 'markdown':
      if (destination === 'wiki') {
        fn = j2m.to_jira.bind(j2m);
      } else if (destination === 'html') {
        fn = j2m.md_to_html.bind(j2m);
      }
      break;
  }

  return fn(text);
};

export default {
  makeHtml: j2m.jira_to_html.bind(j2m),
  makeMarkdown: (htmlValue: string) => j2m.to_jira(
    // convert from HTML to markdown first
    service.turndown(htmlValue)
  ),
  toMarkdown: j2m.to_markdown.bind(j2m),
  format,
};
