import React from 'react';
import type { FC, ReactNode } from 'react';
import { withStyles, Theme, darken } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const DangerButton = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.getContrastText(theme.palette.error.main),
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: darken(theme.palette.error.main, 0.1),
    },
  },
}))(Button);

interface ConfirmOptions {
  title?: string;
  message?: string;
  yesText?: string;
  noText?: string;
}

export interface ConfirmContextValue {
  confirm: (o: ConfirmOptions) => Promise<boolean>;
}

const ConfirmContext = React.createContext<ConfirmContextValue>({
  // eslint-disable-next-line prefer-promise-reject-errors
  confirm: async () => Promise.reject('[ConfirmContext] Not implemented'),
});

export const ConfirmModal: FC<any> = ({ open, title, onClose, onYes, message, yesText, noText }: any) => (
  <div>
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="dialog-title"
    >
      {title && <DialogTitle id="dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onClose}
          color="inherit"
        >
          {noText || 'No'}
        </Button>
        <DangerButton
          disableElevation
          onClick={onYes}
          color="primary"
          variant="contained"
        >
          {yesText || 'Yes'}
        </DangerButton>
      </DialogActions>
    </Dialog>
  </div>
);

interface ConfirmProviderProps {
  children?: ReactNode;
}

let resolveCallback: Function;
export const ConfirmProvider: FC<ConfirmProviderProps> = ({ children }: ConfirmProviderProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = React.useState<boolean>(false);
  const [options, setOptions] = React.useState<ConfirmOptions>({});

  const confirm = (o: ConfirmOptions = {}): Promise<boolean> => {
    setOpen(true);
    setOptions(o);

    return new Promise((resolve) => {
      resolveCallback = resolve;
    });
  };

  return (
    <ConfirmContext.Provider value={{ confirm }}>
      {children}
      {open && (
        <ConfirmModal
          open={open}
          onClose={() => {
            setOpen(false);
            resolveCallback(false);
          }}
          onYes={() => {
            setOpen(false);
            resolveCallback(true);
          }}
          {...options}
        />
      )}
    </ConfirmContext.Provider>
  );
};

export default ConfirmContext;
