import { axiosGraqhqlInstance, axiosRestInstance } from 'src/api/axios';
import {
  JiraProject as Project,
  ProjectInput,
  ProjectQuery,
  PropertyValue,
  LatestUpdatesResult,
} from 'src/types/project';
import { ObjectCollection } from 'src/types/collection';
import { storage as s } from '../utils/storage';
import markdown from '../utils/markdown';

class ProjectApi {
  axios: typeof axiosGraqhqlInstance;

  restAxios: typeof axiosRestInstance;

  constructor(private token?: string) {
    this.axios = axiosGraqhqlInstance;
    this.axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
    this.restAxios = axiosRestInstance;
    this.restAxios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
  }

  async search(query: ProjectQuery = {}): Promise<any> {
    const response = await this.axios.post<{
      projectSearch: ObjectCollection<Project>;
    }>('graphql', {
      query: `query ProjectSearch($query: ProjectQuery){
        projectSearch (query: $query) {
          maxResults
          total
          isLast
          startAt
          values {
            id
            name
            key
            description
            lead {
              displayName
              avatarUrls
            }
            properties
            avatarUrls
            initiator {
              email
              firstName
              lastName
              avatar
            }
            membership {
              role
              active
              email
              user {
                _id
                email
                firstName
                lastName
                avatar
              }
            }
          }
        }
      }`,
      variables: {
        query,
      },
    });

    return response.data.projectSearch;
  }

  async get(id: string | number, query?: ProjectQuery): Promise<any> {
    const response = await this.axios.post<{
      projectGet: Project;
    }>(
      'graphql',
      {
        query: `query ProjectGet($id: String!, $query: ProjectQuery) {
        projectGet (id: $id, query: $query) {
          id
          name
          key
          description
          properties
          avatarUrls
          lead {
            displayName
            avatarUrls
          }
          initiator {
            _id
            email
            firstName
            lastName
            avatar
          }
          membership {
            role
            active
            email
            user {
              _id
              email
              firstName
              lastName
              avatar
            }
          }
        }
      }`,
        variables: {
          id,
          query: query || {
            action: 'edit',
            expand: 'description,lead,insight',
          },
        },
      },
      {
        headers: {
          'x-project-key': String(id),
        },
      }
    );

    return response.data.projectGet;
  }

  async create(payload: ProjectInput): Promise<any> {
    const response = await this.axios.post<{
      projectNew: Project;
    }>('graphql', {
      query: `mutation ProjectCreate($project: ProjectInput!, $properties: JSON){
        projectNew(project: $project, properties: $properties) {
          id
          key
          name
          description
          properties
          avatarUrls
          lead {
            displayName
            avatarUrls
          }
          initiator {
            _id
            email
            firstName
            lastName
            avatar
          }
        }
      }`,
      variables: {
        project: {
          name: payload.name,
          key: payload.name
            .replace(/[^\w]+/gi, '')
            .toUpperCase()
            .substr(0, 10),
          description: `*Who are the users and stake holders?*\n
          ${markdown.format(payload.descriptionUser, 'html -> wiki')}\n\n

          *What is the main purpose of this project?*\n
          ${markdown.format(payload.descriptionPurpose, 'html -> wiki')}
          `,
        },
        properties: {
          startDate: {
            type: 'datetime',
            value: payload.startDate,
          },
          endDate: {
            type: 'datetime',
            value: payload.endDate,
          },
          tags: {
            type: 'array',
            value: payload.tags,
            archived: 'no'
          },
          role: {
            type: 'string',
            value: payload.userRole,
          },
        },
      },
    });

    return response.data.projectNew;
  }

  async update(id: number, payload: ProjectInput): Promise<Project> {
    const properties: { [key: string]: PropertyValue } = {};

    Object.entries(payload).forEach(([key, value]: [string, any]) => {
      // eslint-disable-next-line default-case
      switch (key) {
        case 'tags':
        case 'array':
        case 'startDate':
        case 'endDate':
          properties.tags = {
            type: key,
            value,
          };
          break;
      }
    });

    const response = await this.axios.post<{
      projectUpdate: Project;
    }>(
      'graphql',
      {
        query: `mutation ProjectUpdate($id: Int!, $data: ProjectInput!, $properties: JSON){
        projectUpdate(id: $id, data: $data, properties: $properties) {
          id
          key
          name
          description
          properties
        }
      }`,
        variables: {
          id,
          data: {
            name: payload.name,
            description: `*Who are the users and stake holders?*\n
          ${markdown.format(payload.descriptionUser, 'html -> wiki')}\n\n

          *What is the main purpose of this project?*\n
          ${markdown.format(payload.descriptionPurpose, 'html -> wiki')}
          `,
          },
          properties,
        },
      },
      {
        headers: {
          'x-project-key': id.toString(),
        },
      }
    );

    return response.data.projectUpdate;
  }

  async fetchLatestUpdates(projectKey: string | number, startAt: number = 0): Promise<LatestUpdatesResult> {
    const response = await this.axios.post<{
      projectLatestUpdates: LatestUpdatesResult;
    }>(
      'graphql',
      {
        query: `query ProjectFetchLatestUpdates ($startAt: Int){
            projectLatestUpdates(startAt: $startAt) {
              meta
              value {
                id
                key
                summary
                created
                author {
                  displayName
                  accountId
                  avatarUrls
                }
                status {
                  id
                  name
                }
                type {
                  id
                  name
                  iconUrl
                }
                comments
                changelogs {
                  id
                  author {
                    displayName
                    accountId
                    avatarUrls
                  }
                  created
                  items {
                    field
                    fromString
                    toString
                    to
                    fieldtype
                    fieldId
                  }
                }
              }
            }
          }`,
        variables: {
          startAt,
        },
      },
      {
        headers: {
          'x-project-key': projectKey,
        },
      }
    );

    return response?.data?.projectLatestUpdates;
  }

  async getFiles(id: string): Promise<any[]> {
    const response = await this.axios.post('graphql', {
      query: `query ProjectGetFiles($id: String!,$propKey: String!) {
            projectGetFiles (id: $id,propKey: $propKey) {
              value {
                list {
                  _id
                  mimetype
                  name
                  originalFileName
                  path
                }
              }
            }
          }`,
      variables: {
        id,
        propKey: 'files',
      },
    });

    return response?.data?.projectGetFiles?.value?.list || [];
  }

  async deleteFile(fileId: string, projectId: string | number): Promise<any> {
    const response = await this.restAxios.delete(`files/${fileId}?refId=${projectId}&location=JIRA_PROJECT`);

    return response;
  }

  async inviteMember(
    projectKey: string,
    email: string,
    role: string
  ): Promise<{ email: string; role: string; active: boolean }> {
    const response = await this.axios.post<{
      inviteMember: { email: string; role: string; active: boolean };
    }>(
      'graphql',
      {
        query: `mutation InviteMember($email: String!, $role: String!) {
            inviteMember (email: $email, role: $role) {
              role
              email
              active
            }
          }`,
        variables: {
          email,
          role,
        },
      },
      {
        headers: {
          'x-project-key': projectKey,
        },
      }
    );

    return response?.data?.inviteMember;
  }

  async changeMemberRole(projectKey: string, userId: string, role: string): Promise<{ role: string }> {
    const response = await this.axios.post<{
      changeMemberRole: { role: string };
    }>(
      'graphql',
      {
        query: `mutation ChangeMemberRole($userId: String!, $role: String!) {
            changeMemberRole(userId: $userId, role: $role) {
              role
            }
          }`,
        variables: {
          userId,
          role,
        },
      },
      {
        headers: {
          'x-project-key': projectKey,
        },
      }
    );

    return response?.data?.changeMemberRole;
  }

  async removeMember(projectKey: string, id: string, checkBy: string = 'userId'): Promise<number> {
    const response = await this.axios.post<{
      removeMember: number;
    }>(
      'graphql',
      {
        query: `mutation RemoveMember($id: String!, $checkBy: String!) {
            removeMember(id: $id, checkBy: $checkBy)
          }`,
        variables: {
          id,
          checkBy,
        },
      },
      {
        headers: {
          'x-project-key': projectKey,
        },
      }
    );

    return response?.data?.removeMember;
  }

  async getUserPinnedProject(): Promise<number[]> {
    const response = await this.axios.post<{
      userPinnedProjectGet: {
        id: string;
        user: string;
        projects: number[];
      };
    }>('graphql', {
      query: `
        query GetPinnedProject {
            userPinnedProjectGet {
              _id
              user
              projects
            }
          }
        `,
    });

    return response?.data?.userPinnedProjectGet?.projects || [];
  }

  async pinUserProject(id: number): Promise<void> {
    await this.axios.post('graphql', {
      query: `
        mutation PinUserProject ($id : Int!) {
            pinUserProject(id: $id)
          }
        `,
      variables: {
        id,
      },
    });
  }

  async unPinUserProject(id: number): Promise<void> {
    await this.axios.post('graphql', {
      query: `
        mutation UnPinUserProject ($id : Int!) {
            unPinUserProject(id: $id)
          }
        `,
      variables: {
        id,
      },
    });
  }

  async deleteProject(projectKey: string, id: string): Promise<number> {
    const response = await this.axios.post<{
      deleteProject: number;
    }>(
      'graphql',
      {
        query: `mutation DeleteProject($id: String!) {
          deleteProject(id: $id)
          }`,
        variables: {
          id,
        },
      },
      {
        headers: {
          'x-project-key': projectKey,
        },
      }
    );

    return response?.data?.deleteProject;
  }

  async archiveProject(projectKey: string, id: string, key: string = 'dell'): Promise<number> {
    const response = await this.axios.post<{
      archiveProject: number;
    }>(
      'graphql',
      {
        query: `mutation ArchiveProject($id: String!) {
          archiveProject(id: $id)
        }`,
        variables: {
          id,
          key
        },
      },
      {
        headers: {
          'x-project-key': projectKey,
        },
      }
    );

    return response?.data?.archiveProject;
  }

  async restoreProject(projectKey: string, id: string): Promise<number> {
    const response = await this.axios.post<{
      restoreProject: number;
    }>(
      'graphql',
      {
        query: `mutation RestoreProject($id: String!) {
          restoreProject(id: $id)
        }`,
        variables: {
          id,
        },
      },
      {
        headers: {
          'x-project-key': projectKey,
        },
      }
    );

    return response?.data?.restoreProject;
  }
}

export const projectApi = new ProjectApi(s.recursive('user.token'));
