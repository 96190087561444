import * as gql from 'gql-query-builder';
import { axiosGraqhqlInstance } from 'src/api/axios';
import { JiraIssue as Issue, IssueQuery, BasicIssueInput, UserIssueQuery } from 'src/types/issue';
import { DeliverableInput } from 'src/types/deliverable';
import { ObjectCollection, StandardCollection } from 'src/types/collection';
import { Comment, CommentPayload } from 'src/types/comment';
import { storage as s } from '../utils/storage';
import { jiraConfig } from 'src/config';
import { RequestInput } from 'src/types/request';

export interface GetQueryOptions {
  full?: boolean;
  withSubtasks?: boolean;
}

declare type IssueType = string | number;

class IssueApi {
  axios: typeof axiosGraqhqlInstance;

  constructor(private token?: string) {
    this.axios = axiosGraqhqlInstance;
    this.axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
  }

  async countByType(projectId: string | number, ...types: IssueType[]): Promise<number> {
    const response = await this.axios.post<{
      issueSearch: ObjectCollection<Issue>;
    }>(
      'graphql',
      {
        query: `query IssueSearch($query: IssueQuery) {
        issueSearch(query: $query) {
          total
        }
      }`,
        variables: {
          query: {
            maxResults: 0,
            jql: `issueType IN (${types.join(',')}) AND statusCategory NOT IN ("Done")`,
          },
        },
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );

    return response.data.issueSearch?.total;
  }

  async search(projectId: string, query?: IssueQuery): Promise<Omit<ObjectCollection<Issue>, 'values'> & { issues: Issue[]; }> {
    let priorityComp = '';
    if ((query?.fields || []).includes('priority')) {
      priorityComp = `
      priority {
        id
        name
        iconUrl
      }
      `;
    }

    let attachmentComp = '';
    if ((query?.fields || []).includes('attachment')) {
      attachmentComp = `
      attachment {
        id
        content
        filename
      }
      `;
    }

    const response = await this.axios.post<{
      issueSearch: Omit<ObjectCollection<Issue>, 'values'> & { issues: Issue[]; };
    }>(
      'graphql',
      {
        query: `query IssueSearch($query: IssueQuery) {
        issueSearch(query: $query) {
          maxResults
          startAt
          total
          issues {
            self
            id
            key
            fields {
              summary
              project {
                id
                key
                name
                avatarUrls
              }
              issuetype {
                id
                iconUrl
              }
              ${priorityComp}
              ${attachmentComp}
              description
              parent {
                id
              }
              status {
                id
              }
              customfield_10066 {
                id
                value
              }
              customfield_10062
              customfield_10063
              customfield_10064 {
                id
                value
              }
              worklog {
                total
                worklogs {
                  timeSpentSeconds
                }
              }
            }
          }
        }
      }`,
        variables: {
          query,
        },
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );

    return response.data.issueSearch;
  }

  /* eslint-disable no-tabs */
  /**
   * Searches for user issues based on the given query parameters.
   * @param {UserIssueQuery} query - An object containing the search query parameters, including sort, limit, offset, and query properties.
   * @returns {Promise<UserIssueCollection>} A Promise that resolves to a collection of user issues that match the query parameters.
   * This example show how to format a simple query for a issueSearch.
   *
   * query IssueSearch(
   *  $query: UserIssueQuery = {sort : "-issue", limit: 3, query: {type: "bug"}}
   *	) {
   *	  userIssueSearch(query: $query) {
   *	    user
   *	    issue
   *	    type
   *	  }
   *	}
   */
  /* eslint-enable no-tabs */

  /* eslint-disable @typescript-eslint/quotes */
  async userIssueSearch(query: UserIssueQuery): Promise<Omit<ObjectCollection<Issue>, 'values'> & { issues: Issue[] }> {
    const response = await this.axios.post<{
      userIssueSearch: Omit<ObjectCollection<Issue>, 'values'> & { issues: Issue[] };
    }>('graphql', {
      query: `query UserIssueSearch($query: UserIssueQuery) {userIssueSearch(query: $query) { 
          total 
          issues { 
            id 
            key 
            fields { 
              summary 
              issuetype { 
                id 
                name 
                iconUrl 
              } 
              project { 
                id 
                key 
                name 
                avatarUrls 
              } 
            } 
          } 
        }
      }`,
      variables: {
        query,
      },
    });

    return response.data.userIssueSearch;
  }
  /* eslint-enable @typescript-eslint/quotes */

  async get(projectId: string, id: string | number, opts?: GetQueryOptions): Promise<any> {
    const response = await this.axios.post<{
      issueGet: Issue;
    }>(
      'graphql',
      gql.query({
        operation: 'issueGet',
        variables: {
          id: {
            value: id,
            type: 'String',
            required: true,
          },
        },
        fields: [
          'self',
          'id',
          'key',
          {
            fields: [
              'summary',
              {
                status: ['id'],
              },
              {
                issuetype: ['id', 'iconUrl'],
              },
              'description',
              {
                attachment: ['id', 'content', 'filename'],
              },
              {
                worklog: [
                  'total',
                  {
                    worklogs: ['timeSpentSeconds'],
                  },
                ],
              },
              ...(opts?.full && [
                {
                  priority: ['id'],
                },
                'customfield_10062',
                'customfield_10063',
                {
                  customfield_10064: ['id', 'value'],
                },
                {
                  customfield_10066: ['id', 'value'],
                },
              ]),
              ...(opts?.withSubtasks && [
                {
                  subtasks: [
                    'id',
                    'key',
                    {
                      fields: [
                        'summary',
                        {
                          status: ['id'],
                        },
                        {
                          issuetype: ['id', 'iconUrl'],
                        },
                        'description',
                      ],
                    },
                  ],
                },
              ]),
            ],
          },
        ],
      }),
      {
        headers: {
          'x-project-key': projectId.toString(),
          'x-scope': 'issue,subtasks',
        },
      }
    );

    return response.data.issueGet;
  }

  async createBug(projectId: string, { summary, description, priority }: BasicIssueInput): Promise<Issue> {
    const attrs: any = {};
    if (priority) {
      attrs.priority = {
        id: jiraConfig.fields.priority.options[priority as number].value.toString(),
        value: priority.toString(),
      };
    }

    const response = await this.axios.post<{
      issueNewBug: Issue;
    }>(
      'graphql',
      {
        query: `mutation IssueNewBug($inputs: IssueFieldsInput!){
        issueNewBug(inputs: $inputs) {
          id
          self
          key
        }
      }`,
        variables: {
          inputs: {
            summary,
            description,
            ...attrs,
          },
        },
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );

    return response.data.issueNewBug;
  }

  async createDeliverable(projectId: string, payload: DeliverableInput): Promise<any> {
    const response = await this.axios.post<{
      issueNewDeliverable: Issue;
    }>(
      'graphql',
      {
        query: `mutation IssueNewDeliverable($inputs: IssueDeliverableInput!){
        issueNewDeliverable(inputs: $inputs) {
          id
          self
          key
        }
      }`,
        variables: {
          inputs: {
            summary: payload.summary,
            who: payload.who,
            what: payload.what,
            why: payload.why,
            priority: {
              id: jiraConfig.fields.customfield_10064.options[payload.priority as number].value.toString(),
              value: payload.priority.toString(),
            },
            acceptanceCriteria: payload.acceptanceCriteria,
            issueType: jiraConfig.issueType.DELIVERABLE.value.toString(),
          },
        },
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );

    return response.data.issueNewDeliverable;
  }

  /**
   * Add new option to a select field mapped in a given project
   * also, relies on context feaure
   *
   * @param param0
   * @returns
   */
  async addOptionsToCustomField({
    option,
    contextId,
    fieldId,
    projectId,
  }: {
    option: string;
    contextId: string;
    projectId: string;
    fieldId: string;
  }): Promise<any> {
    const response = await this.axios.post<{
      addOptionsToCustomField: { id: string; value: string }[];
    }>(
      'graphql',
      {
        query: `mutation AddOptionsToCustomField ($fieldId: String!, $contextId: String!, $options: [String]!) {
        addOptionsToCustomField(fieldId:$fieldId, contextId: $contextId, options: $options) {
          id
          value
        }
      }`,
        variables: {
          fieldId,
          contextId,
          options: [option],
        },
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );

    return response.data.addOptionsToCustomField;
  }

  /**
   * Retrieve options for a specific field in a project,
   * under the hood it relies on context feature provided by Jira
   *
   * @param param0
   * @returns
   */
  async getFieldOptionsByProject({
    projectId,
    fieldId,
    issueTypeId,
  }: {
    projectId: string | number;
    fieldId: string;
    issueTypeId: string;
  }): Promise<any> {
    const response = await this.axios.post<{
      getFieldOptionsByProject: {
        contextId: string;
        options: { id: string; value: string }[];
      };
    }>(
      'graphql',
      {
        query: `query GetFieldOptionsByProject ($fieldId: String!, $issueTypeId: String!) {
        getFieldOptionsByProject(fieldId:$fieldId, issueTypeId: $issueTypeId) {
          contextId
          options {
            id
            value
          }
        }
      }`,
        variables: {
          fieldId,
          issueTypeId,
        },
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );

    return response.data.getFieldOptionsByProject;
  }

  async createRequest(projectId: string, payload: RequestInput): Promise<any> {
    let issueType = '';
    const { description } = payload;

    // eslint-disable-next-line default-case
    switch (payload.type) {
      case 'estimate':
        issueType = jiraConfig.issueType.REQUEST_ESTIMATE.value.toString();
        break;
      case 'report':
        issueType = jiraConfig.issueType.REQUEST_REPORT.value.toString();
        break;
      case 'appointment':
        issueType = jiraConfig.issueType.REQUEST_APPT.value.toString();
        break;
    }

    const response = await this.axios.post<{
      issueNewRequest: Issue;
    }>(
      'graphql',
      {
        query: `mutation IssueNewRequest($issueType: String!, $description: Document, $summary: String!){
        issueNewRequest(issueType: $issueType, description: $description, summary: $summary) {
          id
          self
          key
        }
      }`,
        variables: { summary: payload.summary, description, issueType },
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );

    return response.data.issueNewRequest;
  }

  async update(projectId: string, issueId: string, inputs: RequestInput | DeliverableInput): Promise<any> {
    const response = await this.axios.post<{
      issueUpdate: string;
    }>(
      'graphql',
      {
        query: `
      mutation IssueUpdate($issueId: String!, $inputs: IssueFieldsInput!){
        issueUpdate(issueId: $issueId, inputs: $inputs) {
          self
          id
          key
          fields {
            summary
            issuetype {
              id
            }
            description
            priority {
              id
              name
              iconUrl
            }
            attachment {
              id
              content
              filename
            }
            priority {
              id
              name
              iconUrl
            }
            customfield_10066 {
              id
              value
            }
            customfield_10062
            customfield_10063
            customfield_10064 {
              id
              value
            }
          }
        }
      }`,
        variables: { issueId, inputs },
      },
      {
        headers: {
          'x-project-key': projectId.toString(),
        },
      }
    );

    return response.data.issueUpdate;
  }

  async transitionToStatus(projectId: string, issueId: string, statusId: string): Promise<any> {
    const response = await this.axios.post<{
      issueTransition: any;
    }>(
      'graphql',
      {
        query: `
        mutation IssueTransition($id: String!, $status: String!) {
          issueTransition(id: $id, status: $status) {
            self
            id
            key
            fields {
              summary
              issuetype {
                id
                iconUrl
              }
              attachment {
                id
                content
                filename
              }
              description
              parent {
                id
              }
              status {
                id
              }
              customfield_10066 {
                id
                value
              }
              customfield_10062
              customfield_10063
              customfield_10064 {
                id
                value
              }
              customfield_10066 {
                id
                value
              }
            }
          }
        }
      `,
        variables: { id: issueId, status: statusId },
      },
      {
        headers: {
          'x-project-key': projectId,
        },
      }
    );

    return response.data.issueTransition;
  }

  async comment(projectId: string, issueId: string, payload: CommentPayload): Promise<Comment> {
    const response = await this.axios.post<{
      issueComment: Comment;
    }>(
      'graphql',
      {
        query: `
        mutation IssueComment($issueId: String!, $payload: CommentPayload!) {
          issueComment(issueId: $issueId, payload: $payload) {
            id
            body
            created
            author {
              accountId
              displayName
              avatarUrls
            }
            user {
              _id
              lastName
              firstName
              avatar
            }
          }
        }
      `,
        variables: {
          issueId,
          payload,
        },
      },
      {
        headers: {
          'x-project-key': projectId,
        },
      }
    );

    return response.data.issueComment;
  }

  async comments(
    projectId: string,
    issueId: string,
    query: any
  ): Promise<StandardCollection & { comments: Comment[] }> {
    const response = await this.axios.post<{
      issueGetComments: StandardCollection & { comments: Comment[] };
    }>(
      'graphql',
      {
        query: `
        query IssueGetComments($issueId: String!, $query: CommentQuery) {
          issueGetComments(issueId: $issueId, query: $query) {
            total
            maxResults
            startAt
            comments {
              id
              body
              created
              author {
                accountId
                displayName
                avatarUrls
              }
              user {
                _id
                lastName
                firstName
                avatar
              }
            }
          }
        }
      `,
        variables: {
          issueId,
          query,
        },
      },
      {
        headers: {
          'x-project-key': projectId,
        },
      }
    );

    return response.data.issueGetComments;
  }
}

export const issueApi = new IssueApi(s.recursive('user.token'));
