export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID,
};

export const envConfig = {
  graphqlUrl: process.env.REACT_APP_GRAPHQL_URL,
  restUrl: process.env.REACT_APP_REST_URL,
  notifierUrl: process.env.REACT_APP_NOTIFIER_URL,
  calendlyUrl: process.env.REACT_APP_CALENDLY_URL,
};

export const jiraConfig = {
  categories: {
    WAUIO: {
      value: 10000,
      label: 'WAU I/O',
    },
    WEBAPP: {
      value: 10001,
      label: 'Web Application',
    },
    AUTOMATION: {
      value: 10002,
      label: 'Automation',
    },
    INTEGRATION: {
      value: 10003,
      label: 'API Integretion',
    },
    BLOCKCHAIN: {
      value: 10004,
      label: 'Blockchain',
    },
  },
  issueType: {
    TASK: {
      value: 10002,
      label: 'Task',
      slug: 'tasks',
    },
    SUBTASK: {
      value: 10003,
      label: 'Sub-task',
      slug: 'subtasks',
    },
    BUG: {
      value: 10004,
      label: 'Bug',
      slug: 'bugs',
    },
    DELIVERABLE: {
      value: 10105,
      label: 'Deliverable',
      slug: 'deliverables',
    },
    ACCEPTANCE_CRITERIA: {
      value: 10106,
      label: 'Acceptance Criteria',
    },
    CHANGE_REQUEST: {
      value: 10107,
      label: 'Change Request',
    },
    REQUEST_ESTIMATE: {
      value: 10102,
      label: 'Request - Estimate',
      slug: 'requests',
    },
    REQUEST_REPORT: {
      value: 10103,
      label: 'Request - Report',
      slug: 'requests',
    },
    REQUEST_APPT: {
      value: 10104,
      label: 'Request - Appointment',
      slug: 'requests',
    },
  },
  statusCategories: {
    TODO: {
      id: 1,
      value: [10006, 1, 10125],
      label: 'To Do',
    },
    In_PROGRESS: {
      id: 2,
      value: [3, 10123, 10126, 10127],
      label: 'In Progress',
    },
    APPROVED: {
      id: 3,
      value: [10034],
      label: 'Approved',
    },
    DONE: {
      id: 4,
      value: [10001],
      label: 'Done',
    },
  },
  status: {
    DRAFT: {
      value: 10125,
      label: 'Draft',
      color: '#9c9c9c',
      issueTypes: [10002, 10004, 10105, 10003],
    },
    TODO: {
      value: 1,
      label: 'Open',
      color: '#9c9c9c',
      issueTypes: [10002, 10105, 10004, 10106, 10107, 10102, 10103, 10104, 10003],
    },
    IN_PROGRESS: {
      value: 3,
      label: 'In Progress',
      color: '#4179b5',
      issueTypes: [10002, 10105, 10004, 10102, 10103, 10104, 10003],
    },
    AWAITING_CODE_REVIEW: {
      value: 10126,
      label: 'Awaiting Code Review',
      color: '#6CC3E0',
      issueTypes: [10003, 10004, 10105]
    },
    UNDER_CODE_REVIEW: {
      value: 10127,
      label: 'Under Code Review',
      color: '#6CC3E0',
      issueTypes: [10003, 10004, 10105]
    },
    AWAITING_REVIEW: {
      value: 10123,
      label: 'Awaiting Review',
      color: '#9151c2',
      issueTypes: [10002, 10105, 10004, 10106, 10107, 10003],
    },
    READY_FOR_RELEASE: {
      value: 10034,
      label: 'Ready for release',
      color: '#4bbf75',
      issueTypes: [10002, 10004, 10105, 10003],
    },
    DONE: {
      value: 10001,
      label: 'Done',
      color: '#4bbf75',
      issueTypes: [10002, 10105, 10004, 10003],
    },
    ON_HOLD: {
      value: 10006,
      label: 'On Hold',
      color: '#9c9c9c',
      issueTypes: [10002, 10105, 10004, 10003],
    },
    RESOLVED: {
      value: 5,
      label: 'Resolved',
      color: '#4bbf75',
      issueTypes: [10102, 10103, 10104],
    },
    CLOSED: {
      value: 6,
      label: 'Closed',
      color: '#AAAAAA',
      issueTypes: [10106, 10107, 10102, 10103, 10104],
    },
    APPROVED: {
      value: 10124,
      label: 'Approved',
      color: '#4bbf75',
      issueTypes: [10106, 10107],
    },
    AWAITING_SUPPORT: {
      value: 10011,
      label: 'Waiting for support',
      color: '#f5e342',
      issueTypes: [10102, 10103, 10104],
    },
    AWAITING_CUSTOMER: {
      value: 10012,
      label: 'Waiting for customer',
      color: '#9151c2',
      issueTypes: [10102, 10103, 10104],
    },
    PENDING: {
      value: 10013,
      label: 'Pending',
      color: '#AAAAAA',
      issueTypes: [10102, 10103, 10104],
    },
    CANCELED: {
      value: 10014,
      label: 'Canceled',
      color: '#d45959',
      issueTypes: [10102, 10103, 10104],
    },
    ESCALATED: {
      value: 10015,
      label: 'Escalated',
      color: '#4179b5',
      issueTypes: [10102, 10103, 10104],
    },
  },
  custom_field: {
    WHO: {
      value: 'customfield_10066',
      label: '(WHO) As a... ',
    },
  },
  fields: {
    priority: {
      label: 'Priority',
      options: {
        1: {
          value: 1,
          label: 'Highest',
          color: '#cf3e3e',
        },
        2: {
          value: 2,
          label: 'High',
          color: '#e67c7c',
        },
        3: {
          value: 3,
          label: 'Medium',
          color: '#e6bc7c',
        },
        4: {
          value: 4,
          label: 'Low',
          color: '#cfe67c',
        },
        5: {
          value: 5,
          label: 'Lowest',
          color: '#a4c42d',
        },
      },
    },
    customfield_10064: {
      label: 'Priority',
      options: {
        1: {
          value: 10008,
          label: '1',
        },
        2: {
          value: 10009,
          label: '2',
        },
        3: {
          value: 10010,
          label: '3',
        },
        4: {
          value: 10011,
          label: '4',
        },
        5: {
          value: 10012,
          label: '5',
        },
        6: {
          value: 10013,
          label: '6',
        },
        7: {
          value: 10014,
          label: '7',
        },
        8: {
          value: 10015,
          label: '8',
        },
        9: {
          value: 10016,
          label: '9',
        },
      },
    },
  },
};

// Allowed dragg
export const KanbanConfig = {
  // source : [...destinations]
  1: [1, 3],
  2: [3, 1, 2],
  3: [3, 1],
  4: [3],
};

// this config maps all available transition option for the user
// according to issue type and the current status of the issue
// each of issue type are related to specific workflow
// see active workflow scheme to see which one is applied
export const statusTransitions = {
  // deliverables
  10105: {
    1: [10006],
    10125: [10006, 1],
    3: [10006, 10034],
    10126: [10006],
    10127: [10006],
    10123: [10006, 10034],
  },

  // bugs
  10004: {
    1: [10006],
    10125: [10006, 1],
    3: [10006, 10034],
    10126: [10006],
    10127: [10006],
    10123: [10006, 10034],
  },

  // requests
  REQUESTS: {},
  10102: {
    10012: [10011, 10013, 10014, 5],
    10013: [10014, 5],
  },

  10103: {
    10012: [10011, 10013, 10014, 5],
    10013: [10014, 5],
  },

  10104: {
    10012: [10011, 10013, 10014, 5],
    10013: [10014, 5],
  },

  // acceptance criteria
  10106: {
    10123: [
      { to: 1, label: 'Decline / Request Changes' },
      { to: 10124, label: 'Approve' },
    ],
  },

  // change request
  10107: {
    10123: [
      { to: 1, label: 'Decline / Request Changes' },
      { to: 10124, label: 'Approve' },
    ],
  },

  // subtasks
  10003: {
    10123: [
      { to: 1, label: 'Decline / Request Changes' },
      // make ready for release when approved
      { to: 10034, label: 'Approve' },
    ],
  }
};
