import { SprintIssuesCollection } from '../types/sprint';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { parseError } from 'src/utils/error';
import { Sprint, SprintInput, SprintCollection, SprintIssuesInput } from 'src/types/sprint';
import { sprintAPI } from '../api/sprintAPI';
import { JiraIssue } from 'src/types/issue';

export interface SprintTickets {
  id: number;
  tickets: JiraIssue[];
}

interface SprintState {
  sprints: Sprint[];
  type: string;
  current: Sprint | null;
  processing: boolean;
  processingUpdate: boolean;
  loaded: boolean;
  error: Error | null;
  tickets: JiraIssue[];
  backlogs: JiraIssue[];
  sprintTickets: SprintTickets[];
}

const initialState: SprintState = {
  sprints: [],
  type: '',
  current: null,
  processing: false,
  processingUpdate: false,
  loaded: false,
  error: null,
  tickets: [],
  backlogs: [],
  sprintTickets: []
};

export const createSprint = createAsyncThunk<Sprint, { projectId: string } & SprintInput>(
  'sprint/createSprint',
  async ({ projectId, ...createData }, { rejectWithValue }) => {
    try {
      return await sprintAPI.createSprint(projectId, { ...createData, startDate: new Date(createData.startDate).toISOString() });
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const getSprints = createAsyncThunk<any, { projectId: string }>(
  'sprint/getSprints',
  async ({ projectId }, { rejectWithValue }) => {
    try {
      return await sprintAPI.getSprints(projectId);
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const deleteSprint = createAsyncThunk<any, { projectId: string, id: number }>(
  'sprint/deleteSprint',
  async ({ projectId, id }, { rejectWithValue }) => {
    try {
      return await sprintAPI.deleteSprint(projectId, id.toString());
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const editSprint = createAsyncThunk<any, { projectId: string } & SprintInput>(
  'sprint/editSprint',
  async ({ projectId, ...editData }, { rejectWithValue }) => {
    try {
      return await sprintAPI.editSprint(projectId, editData);
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const addIssueSprint = createAsyncThunk<any, { projectId: string } & SprintIssuesInput>(
  'sprint/addIssueSprint',
  async ({ projectId, ...issueData }, { rejectWithValue }) => {
    try {
      return await sprintAPI.addIssueSprint(projectId, issueData);
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const addIssueBacklog = createAsyncThunk<any, { projectId: string } & SprintIssuesInput>(
  'sprint/addIssueBacklog',
  async ({ projectId, ...issueData }, { rejectWithValue }) => {
    try {
      return await sprintAPI.addIssueBacklog(projectId, issueData);
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const fetchIssuesSprint = createAsyncThunk<any, { projectId: string, id: number }>(
  'sprint/fetchIssuesSprint',
  async ({ projectId, id }, { rejectWithValue }) => {
    try {
      return await sprintAPI.fetchIssuesSprint(projectId, id.toString());
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const fetchBoardBacklog = createAsyncThunk<any, { projectId: string }>(
  'sprint/fetchBoardBacklog',
  async ({ projectId }, { rejectWithValue }) => {
    try {
      return await sprintAPI.fetchBoardBacklog(projectId);
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

const initProcess = (state: SprintState) => ({
  ...state,
  loaded: false,
  error: null,
  processing: true,
});

const slice = createSlice({
  name: 'sprint',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    setError(state: SprintState, action: PayloadAction<Error>) {
      state.error = action.payload;
    },
    setCurrent(state: SprintState | null, action: PayloadAction<Sprint>) {
      state.current = action.payload;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    // create deliverable
    builder.addCase(createSprint.pending, initProcess);
    builder.addCase(createSprint.rejected, (state: SprintState, action: any) => {
      state.error = parseError(action);
      state.loaded = false;
      state.processing = false;
    });
    builder.addCase(createSprint.fulfilled, (state: SprintState, action: PayloadAction<Sprint>) => {
      state.sprints.push(action.payload);
      state.current = action.payload;
      state.type = 'sprints';
      state.loaded = true;
      state.processing = false;
    });

    builder.addCase(getSprints.pending, initProcess);
    builder.addCase(getSprints.rejected, (state: SprintState, action: any) => {
      state.error = parseError(action);
      state.loaded = false;
      state.processing = false;
    });
    builder.addCase(getSprints.fulfilled, (state: SprintState, action: PayloadAction<SprintCollection>) => {
      state.sprints = action.payload.values;
      state.type = 'sprints';
      state.loaded = true;
      state.processing = false;
    });

    builder.addCase(editSprint.rejected, (state: SprintState, action: any) => {
      state.error = parseError(action);
    });
    builder.addCase(editSprint.fulfilled, (state: SprintState, action: PayloadAction<Sprint>) => {
      state.sprints = state.sprints.map((sprint) => {
        if (sprint.id === action.payload.id) {
          sprint.name = action.payload.name;
        }
        return sprint;
      });
    });

    builder.addCase(deleteSprint.rejected, (state: SprintState, action: any) => {
      state.error = parseError(action);
    });
    builder.addCase(deleteSprint.fulfilled, (state: SprintState, action: PayloadAction<{ id: number }>) => {
      state.sprints = state.sprints.filter((sprint) => sprint.id !== action.payload.id);
      state.type = 'sprints';
      state.loaded = true;
      state.processing = false;
    });

    builder.addCase(addIssueSprint.rejected, (state: SprintState, action: any) => {
      state.error = parseError(action);
    });
    builder.addCase(addIssueSprint.fulfilled, (state: SprintState, action: PayloadAction<{ issueKey: string }, any, any>) => {
      if (action.meta.arg.sprintSource && action.meta.arg.sprintDestination) {
        let ticketDragged = null;
        let newSprintTickets = state.sprintTickets.slice();
        const sprintSource: SprintTickets = newSprintTickets.find((sprint) => sprint.id === parseInt(action.meta.arg.sprintSource, 10));
        ticketDragged = sprintSource.tickets.find((ticket) => ticket.key === action.meta.arg.issueKey);
        const newSprintTicketsList = sprintSource.tickets.filter((ticket) => ticket.key !== action.meta.arg.issueKey);

        newSprintTickets = newSprintTickets.map((sprint) => {
          if (sprint.id === parseInt(action.meta.arg.sprintSource, 10)) {
            return {
              ...sprint,
              tickets: newSprintTicketsList,
            };
          }
          if (sprint.id === parseInt(action.meta.arg.sprintDestination, 10)) {
            const oldTickets = sprint.tickets.slice();
            return {
              ...sprint,
              tickets: [...oldTickets, ticketDragged],
            };
          }
          return sprint;
        });
        state.sprintTickets = newSprintTickets;
      } else {
        const issue = state.backlogs.find((backlog) => backlog.key === action.payload.issueKey);
        state.tickets.push(issue);
        state.backlogs = state.backlogs.filter((backlog) => backlog.key !== action.payload.issueKey);
      }
      state.type = 'sprints';
      state.loaded = true;
      state.processing = false;
    });

    builder.addCase(fetchIssuesSprint.rejected, (state: SprintState, action: any) => {
      state.error = parseError(action);
    });
    builder.addCase(fetchIssuesSprint.fulfilled, (state: SprintState, action: PayloadAction<SprintIssuesCollection, any, any>) => {
      state.tickets = action.payload.issues;
      const sprintTickets = state.sprintTickets.slice();
      const index = state.sprintTickets.findIndex((sprint) => sprint.id === action.meta.arg.id);
      if (index < 0) {
        const newSprintTicket: SprintTickets = {
          id: action.meta.arg.id,
          tickets: action.payload.issues,
        };
        sprintTickets.push(newSprintTicket);
      } else {
        sprintTickets[index].tickets = action.payload.issues;
      }
      state.sprintTickets = sprintTickets;
    });

    builder.addCase(addIssueBacklog.rejected, (state: SprintState, action: any) => {
      state.error = parseError(action);
    });
    builder.addCase(addIssueBacklog.fulfilled, (state: SprintState, action: PayloadAction<{ issueKey: string }>) => {
      const issue = state.tickets.find((ticket) => ticket.key === action.payload.issueKey);
      state.backlogs.push(issue);
      state.tickets = state.tickets.filter((ticket) => ticket.key !== action.payload.issueKey);
      state.type = 'sprints';
      state.loaded = true;
      state.processing = false;
    });

    builder.addCase(fetchBoardBacklog.pending, initProcess);
    builder.addCase(fetchBoardBacklog.rejected, (state: SprintState, action: any) => {
      state.error = parseError(action);
      state.loaded = false;
      state.processing = false;
    });
    builder.addCase(fetchBoardBacklog.fulfilled, (state: SprintState, action: PayloadAction<SprintIssuesCollection>) => {
      state.backlogs = action.payload.issues;
      state.type = 'sprints';
      state.loaded = true;
      state.processing = false;
    });
  },
});

export const { reducer } = slice;

export const { setCurrent } = slice.actions;

export default slice;
