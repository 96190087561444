import { useCallback, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ControlKeys = ['ControlLeft', 'ControlRight', 'MetaLeft', 'MetaRight'];

export default () => {
  const ctlrRef = useRef<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleCtlrKeyDown = (e: KeyboardEvent) => {
      if (ControlKeys.includes(e.code)) {
        ctlrRef.current = true;
      }
    };
    const handleCtlrKeyUp = (e: KeyboardEvent) => {
      if (ControlKeys.includes(e.code)) {
        ctlrRef.current = false;
      }
    };

    // @ts-ignore
    window.addEventListener('keydown', handleCtlrKeyDown);
    // @ts-ignore
    window.addEventListener('keyup', handleCtlrKeyUp);

    return () => {
      // @ts-ignore
      window.removeEventListener('keydown', handleCtlrKeyDown);
      // @ts-ignore
      window.removeEventListener('keyup', handleCtlrKeyUp);
    };
  }, []);

  return useCallback(
    (href) => {
      if (typeof href === 'number') {
        navigate(href);
      } else if (!ctlrRef.current) {
        window.location.replace(href);
      } else {
        window.open(href, '_blank').focus();
      }
    }, [ctlrRef.current]
  );
};
