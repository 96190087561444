/* eslint-disable no-case-declarations */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import type { FC, ComponentType } from 'react';
import classNames from 'classnames';
import { formatDistanceToNow } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import {
  Avatar,
  IconButton,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Tooltip,
} from '@material-ui/core';
import { makeStyles, Theme, lighten } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Notification } from 'src/types/notification';
import { resolveFileUrl } from 'src/utils/file';
import { useDispatch } from 'src/store';
import useNavigate from 'src/hooks/useNavigate';
import { bulkReadNotifications } from 'src/slices/notifications';
import { iconsMap, notificationAgg, buildIssueLink } from './utils';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    '& .ic-del': {
      display: 'none',
    },
    '&:hover .ic-del': {
      display: 'inherit',
    },
  },
  root: {
    background: lighten(theme.palette.primary.light, 0.92),
    '&:hover': {
      background: lighten(theme.palette.primary.light, 0.88),
      '&.read': {
        background: lighten('#c4c4c4', 0.8),
      },
    },

    '&.read': {
      background: 'inherit',
    },
  },
}));

declare type NotificationItemProps = Notification & {
  ids?: string[];
  Wraper?: ComponentType;
};
interface RunCallback {
  runBefore?: () => any;
  runAfter?: () => any;
}

const NotificationItem: FC<NotificationItemProps> = ({
  type,
  createdAt,
  read,
  ids,
  Wraper,
  ...props
}: NotificationItemProps) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const Icon = iconsMap[type];
  const { title, subtitle, avatar, clickAction } = notificationAgg({
    type,
    ...props,
  });
  const avatarProps: any = {};
  if (avatar) {
    if (avatar.match(/^http/)) {
      avatarProps.src = avatar;
    } else {
      avatarProps.src = resolveFileUrl(avatar);
    }
  }

  const triggerBeforeClickAction = React.useCallback(() => {
    if (clickAction) {
      const [actionName, args] = clickAction;
      switch (actionName) {
        case 'issue.open':
          console.log('Opening issue', ...args);
          navigate(buildIssueLink({
            projectId: args[0],
            issueType: args[1],
            issueId: args[2],
          }));
          break;
        default:
          console.log('clickBeforeAction not yet handled', {
            actionName,
            args,
          });
      }
    }
  }, [props._id]);

  const triggerAfterClickAction = React.useCallback(() => {
    if (clickAction) {
      const [actionName, args] = clickAction;
      // eslint-disable-next-line default-case
      switch (actionName) {
        case 'location.href':
          navigate(args[0] as string);
          break;
      }
    }
  }, [props._id]);

  const handleClick = React.useCallback(
    (targetValue: boolean = true, callback?: RunCallback) => async () => {
      if (callback?.runBefore) {
        callback.runBefore();
      }

      dispatch(bulkReadNotifications({ ids, value: targetValue }));

      if (callback?.runAfter) {
        callback.runAfter();
      }
    },
    [props._id]
  );

  const DefaultWraper = ({ children }: any) => (
    <ListItem
      divider
      sx={{ alignItems: 'flex-start', cursor: 'pointer' }}
      className={classNames(classes.root, { read })}
      ContainerProps={{
        className: classes.container,
      }}
    >
      {children}
    </ListItem>
  );

  const W = Wraper || DefaultWraper;

  return (
    <W>
      <ListItemAvatar sx={{ mt: 1 }}>
        <Avatar
          {...avatarProps}
          sx={{
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
          }}
        >
          <Icon fontSize="small" />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        onClick={handleClick(true, {
          runBefore: triggerBeforeClickAction,
          runAfter: triggerAfterClickAction,
        })}
        primary={(
          <Link
            color="textPrimary"
            sx={{ cursor: 'pointer' }}
            underline="none"
            variant="subtitle2"
          >
            {title}
            <span style={{ fontSize: 13, fontStyle: 'italic', marginLeft: 5 }}>
              -
              {' '}
              {formatDistanceToNow(
                utcToZonedTime(createdAt, Intl.DateTimeFormat().resolvedOptions().timeZone, { timeZone: 'UTC' }),
                {
                  addSuffix: true,
                  includeSeconds: true,
                }
              )}
            </span>
          </Link>
        )}
        secondary={subtitle}
      />
      <ListItemSecondaryAction>
        <Tooltip title={read ? 'Mark as unread' : 'Mark as read'}>
          <IconButton
            edge="end"
            aria-label="delete"
            className="ic-del"
            size="small"
            onClick={handleClick(!read)}
          >
            <CheckCircleIcon
              fontSize="small"
              color={read ? 'primary' : 'disabled'}
            />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </W>
  );
};

export default NotificationItem;
