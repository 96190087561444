import { ReactElement, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import useNotifications from 'src/hooks/useNotifications';
import useConfirm from 'src/hooks/useConfirm';
import useAuth from 'src/hooks/useAuth';
import { useLocalStorage } from 'react-use';

interface PushNotificationConfirmProps {
  children?: ReactNode;
}

export const PushNotificationConfirm: FC<PushNotificationConfirmProps> = ({
  children,
}: PushNotificationConfirmProps): ReactElement => {
  const { confirm } = useConfirm();
  const { pushEnabled, requestPushPermission } = useNotifications();
  const { isAuthenticated } = useAuth();

  const [pushDenied, setPushDenied] = useLocalStorage('push_denied', 'false');

  useEffect(() => {
    if (!pushEnabled && isAuthenticated() && pushDenied === 'false') {
      confirm({
        title: 'Enable Push Notifications',
        message:
          'Stay on top of your Deliverables by enabling push notifications. Receive updates and stay informed about any changes.',
        yesText: 'Yes, enable push notifications',
      }).then((accepted: boolean) => {
        if (accepted) {
          requestPushPermission();
        } else {
          setPushDenied('true');
        }
      });
    }
  }, [pushEnabled, isAuthenticated()]);

  return <>{children}</>;
};
