export interface NotificationValue {
  type?: string;
  label: string;
  active: boolean;
  email: boolean;
  text: boolean;
  push: boolean;
}

export interface NotificationQuery {
  displayOnlyUnread?: boolean;
  query?: any;
  page?: number;
  pageSize?: number;
}

export enum NotificationType {
  // issues
  DELIVERABLE_REVIEW = 'DELIVERABLE_REVIEW',
  DELIVERABLE_DONE = 'DELIVERABLE_DONE',
  DELIVERABLE_ONHOLD = 'DELIVERABLE_ONHOLD',
  ESTIMATE_UPDATED = 'ESTIMATE_UPDATED',

  // comments
  COMMENT_NEW = 'COMMENT_NEW',

  // project
  PROJECT_INVITE = 'PROJECT_INVITE',
}

export interface Notification {
  _id: string;
  type: NotificationType;
  createdAt?: string;
  read?: boolean;
  data?: any;
  user?: {
    displayName: string;
    avatar?: string;
  };
  issue?: {
    id: number;
    key: string;
    fields: {
      summary: string;
      status?: {
        id: number;
        name: string;
      };
      issuetype?: {
        id: string;
        name: string;
        iconUrl: string;
      };
    };
  };
}
