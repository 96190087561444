import { useDispatch } from 'src/store';
import { useFaye } from 'src/faye';
import { appendComment } from 'src/slices/comments';
import { NotifierCommentEvent } from 'src/types/event';

export default (projectId: string, cardId: string) => {
  const dispatch = useDispatch();
  useFaye({
    // handle new comments from jira side
    [`/${projectId}/issues/${cardId}/comment`]: (channel: string, { comment }: NotifierCommentEvent) => {
      if (!comment.user) {
        dispatch(appendComment(comment));
      }
    },
  });
};
