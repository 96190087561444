/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState, useRef, createContext } from 'react';
import type { FC } from 'react';
import LoadingScreen from 'src/components/LoadingScreen';
import { envConfig } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import { loadScript } from 'src/utils/common';
import { storage as s } from 'src/utils/storage';

export declare type FayeListener<T = any> = (channel: string, message: T) => void;

export interface Cancelable {
  cancel: () => void;
}

export interface WithChannel<T = any> {
  withChannel?: (listener?: FayeListener<T>) => Cancelable & Promise<void>;
}

export declare type Subscription = Cancelable & WithChannel;

export interface FayeContextValue {
  subscribe: (channel: string, listener?: FayeListener) => Subscription;
}

export const FayeContext = createContext<FayeContextValue>({
  subscribe: () => {
    // @ts-ignore
    console.warn('[FayeContent] default context calue in use, attempt to subscribe', arguments);
    return {
      cancel: () => {},
      withChannel: () => {},
    } as Subscription;
  },
});

interface FayeProviderProps {
  endpoint?: string;
}

export const FayeProvider: FC<FayeProviderProps> = (props: FayeProviderProps) => {
  // @ts-ignore
  const { children, endpoint } = props;
  const notifierArtifact = `${endpoint || envConfig.notifierUrl}/client.js`;
  const user = useAuth();
  const client = useRef<any>();

  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    // load faye artifact then set the app as loaded
    // @todo: handle too long loading -> print an error
    loadScript(notifierArtifact).then(() => {
      // setup client
      // @ts-ignore
      client.current = new window.Faye.Client(endpoint || envConfig.notifierUrl, { retry: 5 });

      setLoaded(true);
      console.info(`Loaded ${notifierArtifact} successfully`);
    });
  }, []);

  let extensionAdded = false;
  useEffect(() => {
    if (!extensionAdded && user.isAuthenticated && client.current) {
      // adding token into faye client while subscribing
      client.current.addExtension({
        outgoing: (message: any, callback: (message: any) => void) => {
          if (message.channel !== '/meta/subscribe') {
            return callback(message);
          }

          if (!message.ext) {
            message.ext = {};
          }

          message.ext.token = s.recursive('user/token');

          return callback(message);
        },
      });
      extensionAdded = true;
    }
  }, [user.isAuthenticated, client.current]);

  if (!loaded) {
    return <LoadingScreen />;
  }

  return (
    // @ts-ignore
    <FayeContext.Provider value={client.current}>{children}</FayeContext.Provider>
  );
};

export default FayeContext;
