import { createContext, useEffect, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { ProjectQuery } from '../types/project';

const STORAGE_KEY = 'project_filter';

export interface FilterContextValue {
  values: ProjectQuery;
  options?: any;
  updateFilter: (query: ProjectQuery) => void;
}

interface FilterProviderProps {
  children?: ReactNode;
}

const initialValues: ProjectQuery = {
  startAt: 0,
  maxResults: 6,
  orderBy: 'lastIssueUpdatedTime',
  action: 'edit',
  expand: 'description,lead,insight',
  status: 'live',
};

const options: any = {
  orderBy: [
    { label: 'Project Key', value: 'key' },
    { label: 'Name', value: 'name' },
    { label: 'Last Updated', value: 'lastIssueUpdatedTime', isDefault: true },
    { label: 'Issue Count', value: 'issueCount' },
  ],
};

const FilterContext = createContext<FilterContextValue>({
  values: { ...initialValues },
  options,
  updateFilter: () => {},
});

export const restoreFilterValues = (): ProjectQuery | null => {
  let settings = null;

  try {
    const storedData: string | null = window.localStorage.getItem(STORAGE_KEY);

    if (storedData) {
      settings = JSON.parse(storedData);
    } else {
      settings = { ...initialValues };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return settings;
};

export const storeFilterValues = (values: ProjectQuery): void => {
  window.localStorage.setItem(STORAGE_KEY, JSON.stringify(values));
};

export const FilterProvider: FC<FilterProviderProps> = (props) => {
  const { children } = props;
  const [values, setValues] = useState<ProjectQuery>({ ...initialValues });

  useEffect(() => {
    const restoredValues = restoreFilterValues();

    if (restoredValues) {
      setValues(restoredValues);
    }
  }, []);

  const updateFilter = (query: ProjectQuery): void => {
    const updatedValues = { ...values, ...query };
    setValues(updatedValues);
    storeFilterValues(updatedValues);
  };

  return (
    <FilterContext.Provider
      value={{
        values,
        options,
        updateFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

FilterProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const FilterConsumer = FilterContext.Consumer;

export default FilterContext;
