import { useRef, useState } from 'react';
import type { FC } from 'react';
import {
  Badge,
  Box,
  Button,
  List,
  Popover,
  Tooltip,
  Typography,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Switch,
} from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import { ClearAll as ClearAllIcon } from '@material-ui/icons';
import BellIcon from 'src/icons/Bell';
import useNotifications from 'src/hooks/useNotifications';
import type { Notification } from 'src/types/notification';
import NotificationItem from './NotificationItem';

const SwitchWrapper = experimentalStyled('div')({
  '& .MuiFormControlLabel-label': {
    fontSize: 13,
  },
});

const NotificationsPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const {
    notification,
    hasMore,
    loadNotifications,
    readAllNotifications,
    switcher: [displayUnread, toggleChecked],
  } = useNotifications();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLoadMore = () => {
    loadNotifications({ page: (notification.meta.page || 1) + 1 });
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton
          color="inherit"
          ref={anchorRef}
          onClick={handleOpen}
        >
          <Badge
            color="error"
            badgeContent={notification?.meta?.count}
          >
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom',
        }}
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 380, maxHeight: 400 },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
            sx={{ display: 'inline' }}
          >
            Notifications
          </Typography>
          <Box
            sx={{
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              float: 'right',
            }}
          >
            <SwitchWrapper>
              <FormControlLabel
                control={(
                  <Switch
                    size="small"
                    color="primary"
                    checked={displayUnread}
                    onChange={toggleChecked}
                  />
)}
                label="Only show unread"
                labelPlacement="start"
              />
            </SwitchWrapper>
            <Tooltip title="Mark all as read">
              <IconButton
                style={{ marginLeft: 8 }}
                size="small"
                onClick={readAllNotifications}
              >
                <ClearAllIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
        {notification.values.length === 0 ? (
          <Box sx={{ p: 2 }}>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              There are no notifications
            </Typography>
          </Box>
        ) : (
          <>
            <List disablePadding>
              {notification.values.map((n: Notification) => (
                <NotificationItem
                  // eslint-disable-next-line no-underscore-dangle
                  key={n._id}
                  {...n}
                />
              ))}
            </List>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
              }}
            >
              <Button
                color="primary"
                size="small"
                variant="text"
                disabled={!hasMore}
                onClick={handleLoadMore}
                startIcon={notification.processing ? <CircularProgress size="1rem" /> : null}
              >
                Load more
              </Button>
            </Box>
          </>
        )}
      </Popover>
    </>
  );
};

export default NotificationsPopover;
