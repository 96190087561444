import { useEffect } from 'react';
import type { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import './i18n';
import SplashScreen from './components/SplashScreen';
import { gtmConfig } from './config';
import { ConfirmProvider } from './contexts/ConfirmContext';
import { NotificationProvider } from './contexts/NotificationsContext';
import { InvitationFlowProvider } from './contexts/InvitationContext';
import SnackbarProvider from './providers/SnackbarProvider';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import gtm from './lib/gtm';
import routes from './routes';
import { createCustomTheme } from './theme';
import { PushNotificationConfirm } from './components/dashboard/notification/PushNotificationConfirm';

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();

  useScrollReset();

  useEffect(() => {
    gtm.initialize(gtmConfig);
  }, []);

  const theme = createCustomTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
  });

  return (
    <ThemeProvider theme={theme}>
      <InvitationFlowProvider>
        <SnackbarProvider>
          <NotificationProvider>
            <ConfirmProvider>
              <PushNotificationConfirm>
                <CssBaseline />
                <Toaster position="top-center" />
                {auth.isInitialized ? content : <SplashScreen />}
              </PushNotificationConfirm>
            </ConfirmProvider>
          </NotificationProvider>
        </SnackbarProvider>
      </InvitationFlowProvider>
    </ThemeProvider>
  );
};

export default App;
