/* eslint-disable no-case-declarations */
import { useDispatch } from 'src/store';
import { useFaye } from 'src/faye';
import { updateIssueData, updateSubtaskData } from 'src/slices/issue';
import { jiraConfig } from 'src/config';
import { changelogRawValue, changelogValue } from 'src/utils';
import type { IssueUpdatePayload, NotifierIssueUpdateEvent, SubtaskUpdatePayload } from 'src/types/event';
import { ChangeDetails } from 'src/types/project';

export default (projectId: string, issueId: string, subtaskIds?: string[]) => {
  const dispatch = useDispatch();
  useFaye({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [`/${projectId}/issues/**`]: (
      channel,
      { changelog, params, issueType, parent }: NotifierIssueUpdateEvent<{ issueId: string }>
    ) => {
      if (channel.match(new RegExp(`/issues/(${[issueId, ...(subtaskIds || [])].join('|')})/update`, 'ig'))) {
        switch (parseInt(issueType.id, 10)) {
          case jiraConfig.issueType.ACCEPTANCE_CRITERIA.value:
          case jiraConfig.issueType.CHANGE_REQUEST.value:
            const childrenPayload: SubtaskUpdatePayload = {
              parentId: parent?.id.toString(),
              issueId: params.issueId,
              data: (changelog?.items || [])
                .filter((item: ChangeDetails) => ['status'].includes(item.fieldId))
                .reduce(
                  (result: Record<string, any>, item: ChangeDetails) => ({
                    ...result,
                    ...changelogValue(item),
                  }),
                  {}
                ),
              raw: (changelog?.items || [])
                .filter((item: ChangeDetails) => ['status'].includes(item.fieldId))
                .reduce(
                  (result: Record<string, any>, item: ChangeDetails) => ({
                    ...result,
                    ...changelogRawValue(item),
                  }),
                  {}
                ),
            };

            if (Object.keys(childrenPayload.raw).length === 0 && Object.keys(childrenPayload.data).length === 0) {
              // only dispatch when payload is not empty
              return;
            }

            dispatch(updateSubtaskData(childrenPayload));
            break;
          case jiraConfig.issueType.DELIVERABLE.value:
          case jiraConfig.issueType.BUG.value:
          case jiraConfig.issueType.SUBTASK.value:
            const parentPayload: IssueUpdatePayload = {
              issueId: params.issueId,
              data: (changelog?.items || [])
                .filter((item: ChangeDetails) => ['status', 'customfield_10064', 'priority'].includes(item.fieldId))
                .reduce(
                  (result: Record<string, any>, item: ChangeDetails) => ({
                    ...result,
                    ...changelogValue(item),
                  }),
                  {}
                ),
              raw: (changelog?.items || [])
                .filter((item: ChangeDetails) => ['status', 'customfield_10064', 'priority'].includes(item.fieldId))
                .reduce(
                  (result: Record<string, any>, item: ChangeDetails) => ({
                    ...result,
                    ...changelogRawValue(item),
                  }),
                  {}
                ),
            };

            if (Object.keys(parentPayload.raw).length === 0 && Object.keys(parentPayload.data).length === 0) {
              // only dispatch when payload is not empty
              return;
            }

            dispatch(updateIssueData(parentPayload));
            break;
          default:
            console.warn(`issue type [${issueType?.name}] is not handled`);
            break;
        }
      }
    },
  });
};
