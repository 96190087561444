import axios from 'axios';
import { envConfig } from 'src/config';

const axiosGraqhqlInstance = axios.create({
  baseURL: envConfig.graphqlUrl,
});

const axiosRestInstance = axios.create({
  baseURL: envConfig.restUrl,
});

axiosGraqhqlInstance.interceptors.response.use((response) => {
  // Intercept graphql
  const { data, errors } = response.data;

  // Fix Issue error in notification list
  // if (errors && data && data.notificationsList) {
  //   response.data = { ...data };
  //   return response;
  // }

  if (errors && errors.length > 0) {
    if (errors[0].message === 'failed to verify token: jwt expired') {
      // Just force to reload the page, will be catched by guard and redirected to login
      window.location.reload();
    }
    throw new Error(errors[0].message);
  }

  if (data && !errors) {
    response.data = { ...data };
  }

  return response;
});

export { axiosGraqhqlInstance, axiosRestInstance };
