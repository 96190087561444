/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import type { PayloadAction } from '@reduxjs/toolkit';
import { issueApi } from '../api/issueApi';
import type { LoadableState } from 'src/types/common';
import type { StandardCollection } from 'src/types/collection';
import { Comment, CommentPayload } from 'src/types/comment';
import { parseError } from 'src/utils/error';

interface CommentQuery {
  maxResults?: number;
  startAt?: number;
  expand?: string;
  orderBy?: string;
}

interface CommentState extends LoadableState<Comment[]> {
  anchorId: string | null;
  paginate?: {
    startAt: number;
    total: number;
    maxResults?: number;
  };
}

const initialState: CommentState = {
  anchorId: null,
  values: [],
  processing: false,
  loaded: false,
  error: null,
};

export const issueComment = createAsyncThunk<Comment, { id: string; payload: CommentPayload }>(
  'comment/create',
  async ({ id, payload }, { rejectWithValue, getState }) => {
    try {
      const { project }: any = getState();
      const comment = await issueApi.comment(project.current.id, id, payload);

      return { ...comment, body: payload.body };
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const loadIssueComments = createAsyncThunk<
StandardCollection & { comments: Comment[] },
{ id: string; query?: CommentQuery }
>('comment/fetch', async ({ id, query }, { rejectWithValue, getState }) => {
  if (!query) {
    query = {};
  }

  if (!query.startAt) {
    query.startAt = 0;
  }

  if (!query.maxResults) {
    query.maxResults = 5;
  }

  if (!query.expand) {
    query.expand = 'properties';
  } else {
    query.expand += ',properties';
  }

  if (!query.orderBy) {
    query.orderBy = '-created';
  }

  try {
    const { project }: any = getState();
    const response = await issueApi.comments(project.current.id, id, query);

    return response;
  } catch (e) {
    if (!e.response) {
      throw e;
    }

    return rejectWithValue(e.response);
  }
});

const removeSignature = (c: Comment): Comment => {
  if (c.user) {
    const content: any[] = c.body?.content;
    if (content.length > 1 && content[content.length - 1].type === 'panel') {
      content.pop();
    }
  }

  return c;
};

const slice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    flushCurrent(state: CommentState) {
      state.processing = false;
      state.loaded = false;
      state.paginate = null;
      state.anchorId = null;
      state.values = [];
    },
    setProcessing(state: CommentState) {
      state.processing = true;
    },
    appendComment(state: CommentState, action: PayloadAction<Comment>) {
      state.values = [...state.values, action.payload];

      // increment pagination result to ensure next time clicking
      // on "Load More" we will get correct next range of data
      if (state.paginate) {
        state.paginate.total++;
        state.paginate.startAt++;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadIssueComments.pending, (state) => ({
      ...state,
      loaded: false,
      processing: true,
      error: null,
    }));
    builder.addCase(
      loadIssueComments.fulfilled,
      (
        state: CommentState,
        action: PayloadAction<StandardCollection & { comments: Comment[] }, string, { arg?: { id?: string } }>
      ) => {
        const { comments, ...payload } = action.payload;
        state.values = [...comments.map(removeSignature).reverse(), ...state.values];
        state.paginate = payload;
        state.processing = false;
        state.loaded = true;
        state.anchorId = action?.meta?.arg?.id;
      }
    );
    builder.addCase(loadIssueComments.rejected, (state: CommentState, action: any) => {
      state.error = parseError(action);
      state.processing = false;
      state.loaded = false;
    });

    builder.addCase(issueComment.fulfilled, (state: CommentState, action: PayloadAction<Comment>) => {
      state.values = [...state.values, action.payload];
      state.processing = false;
      state.loaded = false;
    });
  },
});

export const { reducer } = slice;
export const {
  actions: { setProcessing, flushCurrent, appendComment },
} = slice;

export default slice;
