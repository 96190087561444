import { axiosGraqhqlInstance } from 'src/api/axios';
import { UserSettings } from '../types/user';
import { storage as s } from '../utils/storage';

class SettingsApi {
  axios: typeof axiosGraqhqlInstance;

  constructor(private token?: string) {
    this.axios = axiosGraqhqlInstance;
    this.axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
  }

  async settingsUpdate(userSettings: UserSettings): Promise<UserSettings> {
    const response = await this.axios.post<{
      updateUserSettings: {
        userSettings: UserSettings
      }
    }>('graphql', {
      query: `
      mutation UpdateUserSettings($userSettings: JSON) {
        updateUserSettings(userSettings: $userSettings) {
          userSettings
        }
      }
      `,
      variables: { userSettings },
    });

    return response.data.updateUserSettings.userSettings;
  }
}

export const settingsApi = new SettingsApi(s.recursive('user.token'));
