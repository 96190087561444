import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ProjectSettings } from 'src/types/project';
import { LoadableState } from 'src/types/common';
import { issueApi } from 'src/api/issueApi';
import { parseError } from 'src/utils/error';

interface SettingsState extends LoadableState<ProjectSettings> {}

const initialState: SettingsState = {
  values: {},
  loaded: false,
  processing: false,
};

export const loadProjectSettings = createAsyncThunk<
ProjectSettings,
{ projectId: string | number; fieldId: string; issueTypeId: string }
>(
  'settings/load',
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async ({ projectId, fieldId, issueTypeId }, { rejectWithValue }) => {
    try {
      const settings: ProjectSettings = {
        fields: {},
      };

      // retrieve options for who field
      const whoField = await issueApi.getFieldOptionsByProject({
        projectId,
        fieldId,
        issueTypeId,
      });

      settings.fields.WHO = {
        id: 'customfield_10066',
        type: 'select',
        ...whoField,
      };

      return settings;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const addOptionsToCustomField = createAsyncThunk<
{ id: string; value: string },
{ option: string; contextId: string; projectId: string; fieldId: string }
>('settings/addFieldOption', async ({ option, contextId, fieldId, projectId }, { rejectWithValue }) => {
  try {
    return await issueApi.addOptionsToCustomField({
      option,
      fieldId,
      contextId,
      projectId,
    });
  } catch (e) {
    if (!e.response) {
      throw e;
    }

    return rejectWithValue(e.response);
  }
});

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  extraReducers: (builder) => {
    builder.addCase(loadProjectSettings.fulfilled, (state: SettingsState, action: PayloadAction<ProjectSettings>) => {
      state.loaded = true;
      state.processing = false;
      state.values = action.payload;
    });

    // add new option
    builder.addCase(addOptionsToCustomField.pending, (state) => ({
      ...state,
      processing: true,
    }));

    builder.addCase(addOptionsToCustomField.rejected, (state: SettingsState, action: any) => {
      state.error = parseError(action);
      state.processing = false;
    });

    builder.addCase(
      addOptionsToCustomField.fulfilled,
      (state: SettingsState, action: PayloadAction<{ id: string; value: string }>) => {
        state.processing = false;
        state.values.fields.WHO.options = [...(state.values.fields.WHO.options || []), action.payload[0]];
      }
    );
  },
});

export const { reducer } = slice;

export default slice;
