import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import useInvitationFlow from 'src/hooks/useInvitationFlow';
import Login from 'src/pages/authentication/Login';

interface AuthGuardProps {
  children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const auth = useAuth();
  const location = useLocation();
  const { key } = useParams();
  const { initialize, isPending } = useInvitationFlow();
  const [requestedLocation, setRequestedLocation] = useState(null);

  if (!auth.isAuthenticated()) {
    if (location.pathname.match(/\/invitation\/(.*)/) && !isPending()) {
      initialize(key);
    }

    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
