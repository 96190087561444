/* eslint-disable no-underscore-dangle */
/* eslint-disable no-await-in-loop */
import { chain, difference } from 'lodash';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import notificationApi from '../api/notificationApi';
import { RootState } from 'src/store';
import { parseError } from 'src/utils/error';
import type { LoadableState } from 'src/types/common';
import type { RowsCollection } from 'src/types/collection';
import type { Notification, NotificationQuery } from 'src/types/notification';

declare type RowsOf<D> = RowsCollection<D> & { countUnread?: number };
declare type ENotification = Notification & { count: number; ids?: string[] };

export interface NotificationState extends LoadableState<ENotification[]> {
  meta: {
    total?: number;
    page?: number;
    pageSize?: number;
    count?: number;
    totalPages?: number;
  };
}

export const initialState: NotificationState = {
  values: [],
  processing: false,
  loaded: false,
  error: null,
  meta: {
    page: 1,
    count: 0,
    pageSize: 20,
    total: 0,
    totalPages: 0,
  },
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const aggregateList = (list: Notification[]) => chain(list)
  .groupBy((n: Notification) => `${n.type}.${n.issue?.id}`)
  .map(
    // only take the 1st one in the group, the rest are considered as similar
    (g: Notification[]) => ({
      ...g[0],
      // read state is an agregated value: set to true if all
      // items in the group is marked as read
      read: g.filter(({ read }: Notification) => !read).length === 0,
      count: g.length,
      ids: g.map(({ _id }: Notification) => _id),
    } as ENotification)
  )
  .value();

const mergeListWith = (baseList: ENotification[], incomingList: Notification[]): ENotification[] => {
  const inList = baseList.map((n: Notification) => `${n.type}.${n.issue?.id}`);

  if (inList.length === 0) {
    return aggregateList(incomingList);
  }

  return incomingList.reduce((acc: ENotification[], n: Notification) => {
    const key = `${n.type}.${n.issue?.id}`;
    const index = inList.indexOf(key);

    if (index > -1) {
      // update the existing notification
      acc[index] = {
        ...acc[index],
        read: !acc[index].read ? false : n.read,
        count: acc[index].count + 1,
        ids: [...acc[index].ids, n._id],
      };
    } else {
      // add a new notification
      acc.push({
        ...n,
        read: n.read,
        count: 1,
        ids: [n._id],
      });
    }

    return acc;
  }, baseList);
};

export const loadUserNotifications = createAsyncThunk<RowsOf<Notification>, NotificationQuery>(
  'notification/load',
  async ({ query, page, pageSize, displayOnlyUnread }: NotificationQuery, { rejectWithValue, getState, dispatch }) => {
    try {
      // @ts-ignore
      const {
        notification: { meta },
      }: RootState = getState();
      const n: RowsOf<Notification> = await notificationApi.query({
        query,
        page: page || meta?.page || 1,
        pageSize: pageSize || meta?.pageSize || 20,
        displayOnlyUnread
      });

      if (displayOnlyUnread) {
        // only when displayOnlyUnread=true, run more requests if needed.
        // if what is returned above doesn't contains any unread, run next
        // range of requests recursively until we get some unread
        const { countUnread, rows } = n;
        if (countUnread > 0 && rows.filter(({ read }: Notification) => !read).length === 0 && n.page < n.totalPages) {
          dispatch(loadUserNotifications({ query, page: page + 1, pageSize, displayOnlyUnread }));
        }
      }

      return n;
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const bulkReadNotifications = createAsyncThunk<any, { ids: string[]; value?: boolean }>(
  'notification/bulkRead',
  async ({ ids, value }, { rejectWithValue }) => {
    try {
      return await notificationApi.bulkRead(ids, value);
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

export const bulkReadAllNotifications = createAsyncThunk<any>(
  'notification/bulkRead/all',
  async (_, { rejectWithValue }) => {
    try {
      return await notificationApi.readAll();
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

const slice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    clearState() {
      return initialState;
    },
    appendNotification(state: NotificationState, action: PayloadAction<Notification>) {
      // check if there is already a notification with the same type and issue id which is still
      // marked as unread, if so, just update the ids list, count remains the same
      // otherwise, add a new notification to the list and increase the count
      const inList = state.values.filter(({ read }: Notification) => !read).map((n: Notification) => `${n.type}.${n.issue?.id}`);
      const key = `${action.payload.type}.${action.payload.issue?.id}`;
      const index = inList.indexOf(key);

      if (index > -1) {
        state.values[index].ids.push(action.payload._id);
        state.values[index].count++;
      } else {
        state.values = [
          {
            ...action.payload,
            ids: [action.payload._id],
            count: 1,
          },
          ...state.values,
        ];
        state.meta.count++;
        state.meta.total++;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadUserNotifications.pending, (state: NotificationState) => {
      state.loaded = false;
      state.processing = true;
      state.error = null;
    });
    builder.addCase(
      loadUserNotifications.fulfilled,
      (state: NotificationState, action: PayloadAction<RowsOf<Notification>>) => {
        const values = mergeListWith(state.values, action.payload.rows);

        state.loaded = true;
        state.processing = false;
        state.values = values;
        state.meta = {
          page: action.payload.page,
          total: action.payload.total,
          pageSize: action.payload.pageSize,
          totalPages: action.payload.totalPages,

          // count of unread notifications is ensured with the countUnread property from graphql query
          // see backend implementation about how it is retrieved at NotiicationService->hooks.before.list
          count: action.payload.countUnread,
        };
      }
    );
    builder.addCase(loadUserNotifications.rejected, (state: NotificationState, action: any) => {
      state.error = parseError(action);
      state.processing = false;
      state.loaded = false;
    });
    // @ts-ignore
    builder.addCase(
      bulkReadNotifications.fulfilled,
      (state: NotificationState, action: PayloadAction<any, string, { arg?: { ids: string[]; value: boolean } }>) => {
        const ids = action?.meta?.arg?.ids;
        state.values = state.values.map(({ read, ...n }: ENotification) => ({
          ...n,
          read: difference(ids, n.ids).length === 0 ? action?.meta?.arg?.value : read,
        }));
        state.meta.count = action.payload.countUnread;
      }
    );
    builder.addCase(bulkReadAllNotifications.fulfilled, (state: NotificationState) => {
      state.values = state.values.map((n: ENotification) => ({
        ...n,
        read: true,
      }));
      state.meta.count = 0;
    });
  },
});

export const { reducer } = slice;

export const {
  actions: { appendNotification },
} = slice;

export default slice;
