import { axiosGraqhqlInstance } from './axios';
import { storage as s } from '../utils/storage';

class InvitationApi {
  axios: typeof axiosGraqhqlInstance;

  constructor(private token?: string) {
    this.axios = axiosGraqhqlInstance;
    if (this.token) {
      this.axios.defaults.headers.common.Authorization = `Bearer ${this.token}`;
    }
  }

  async resolve(key: string): Promise<any> {
    const response = await this.axios.post<{
      resolveInvitationLink: any;
    }>('graphql', {
      query: `query ResolveInvitationLink($key: String!) {
            resolveInvitationLink(key: $key)
          }`,
      variables: {
        key,
      },
    });

    return response?.data?.resolveInvitationLink;
  }

  async accept(email: string, projectId: number): Promise<any> {
    const response = await this.axios.post<{
      acceptInvitation: any;
    }>('graphql', {
      query: `mutation acceptInvitation($email: String!, $projectId: Int!) {
            acceptInvitation(email: $email, projectId: $projectId) {
              role
              email
              active
            }
          }`,
      variables: {
        email,
        projectId,
      },
    });

    return response?.data?.acceptInvitation;
  }
}

export default new InvitationApi(s.recursive('user.token'));
