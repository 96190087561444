import { useSnackbar } from 'notistack';
import { useDispatch } from 'src/store';
import { appendNotification } from 'src/slices/notifications';
import { Notification as N } from 'src/types/notification';
import { notificationAgg } from 'src/components/dashboard/notification/utils';
import { useFaye } from 'src/faye';

export default (userId: string) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useFaye(
    {
      [`/${userId}/notifications`]: async (channel: string, payload: N) => {
        await dispatch(appendNotification(payload));

        const { title, subText } = notificationAgg(payload);

        enqueueSnackbar([title, subText].join(' / '), {
          // eslint-disable-next-line no-underscore-dangle
          key: payload._id,
          preventDuplicate: true,
          variant: 'info',
        });
      },
    },
    [userId]
  );
};
