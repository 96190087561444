import { chain } from 'lodash';
import { jiraConfig } from 'src/config';
import { Attachment } from 'src/types/kanban';
import { Worklog } from 'src/types/issue';
import { ChangeDetails } from 'src/types/project';

export const worklogDuration = (worklogs: Worklog[]): string => {
  const minutes = worklogs.reduce((prev: number, w: Worklog) => prev + w.timeSpentSeconds / 60, 0);

  const result: string[] = [];

  const h = Math.floor((minutes) / 60);
  const m = Math.floor((minutes % 60) / 1);

  if (h > 0) {
    result.push(`${h}h`);
  }

  if (m > 0) {
    result.push(`${m}m`);
  }

  return result.join(' ');
};

export const fieldId = (jiraId: string) => {
  switch (jiraId) {
    case 'customfield_10064':
      return 'priority';
    default:
      return jiraId;
  }
};

export const changelogItemToValue = (item: ChangeDetails) => {
  switch (item.fieldId) {
    case 'customfield_10064':
      return chain(jiraConfig.fields.customfield_10064.options)
        .filter((p) => p.value === parseInt(item.to, 10))
        .first()
        .get('label')
        .value();
    case 'priority':
      return chain(jiraConfig.fields.priority.options)
        .filter((p) => p.value === parseInt(item.to, 10))
        .first()
        .get('value')
        .value();
    case 'status':
      return parseInt(item.to, 10);
    default:
      return item;
  }
};

export const changelogItemToRawValue = (item: ChangeDetails) => {
  switch (item.fieldId) {
    case 'customfield_10064':
    case 'priority':
      return {
        id: item.to,
        value: item.toString,
      };
    case 'status':
      return {
        id: parseInt(item.to, 10),
        name: item.toString,
      };
    default:
      return item.toString;
  }
};

export const changelogValue = (item: ChangeDetails): Record<string, any> => ({
  [fieldId(item.fieldId)]: changelogItemToValue(item),
});

export const changelogRawValue = (item: ChangeDetails): Record<string, any> => ({
  [item.fieldId]: changelogItemToRawValue(item),
});

export const attachmentToFile = (attachment: Attachment) => ({
  _id: attachment?.id,
  mimetype: '',
  name: attachment?.filename,
  originalFileName: attachment?.filename,
  path: '',
});
