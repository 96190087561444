/* eslint-disable no-underscore-dangle */
import { createContext, useEffect, useCallback, useState, useMemo } from 'react';
import type { FC, PropsWithChildren } from 'react';
import useAuth from 'src/hooks/useAuth';
import useMounted from 'src/hooks/useMounted';
import { storage as s } from 'src/utils/storage';
import { NotificationValue, NotificationType, NotificationQuery, Notification } from 'src/types/notification';
import {
  loadUserNotifications,
  bulkReadAllNotifications,
  NotificationState,
  initialState,
} from 'src/slices/notifications';
import { useDispatch, useSelector } from 'src/store';
import { listenToNotifications } from 'src/faye/listener';

function createData(
  label: string,
  type: NotificationType,
  email: boolean,
  text: boolean,
  push: boolean,
  active: boolean
): NotificationValue {
  return { label, type, email, text, push, active };
}

const defaultNotificationsSettings: NotificationValue[] = [
  createData('Deliverable Awaiting review', NotificationType.DELIVERABLE_REVIEW, false, false, false, true),
  createData('Deliverable set to Done', NotificationType.DELIVERABLE_DONE, false, false, false, true),
  createData('Deliverable set to On Hold', NotificationType.DELIVERABLE_ONHOLD, false, false, false, true),
  createData('New comments from other users', NotificationType.COMMENT_NEW, false, false, false, true),
  createData('Estimate request updated', NotificationType.ESTIMATE_UPDATED, false, false, false, true),
  createData('Invitation to another project', NotificationType.PROJECT_INVITE, false, false, false, true),
];

export interface NotificationContextValue {
  // dealing with settings
  settings: NotificationValue[];
  capabilities: Record<'push' | 'email' | 'text', boolean>;
  updateNotificationsFlag: (v: NotificationValue[]) => Promise<NotificationValue[]>;
  localUpdate: (v: NotificationValue[]) => void;

  // handle notifications displaying
  query: NotificationQuery;
  setQuery: (v: NotificationQuery) => void;
  loadNotifications: (v: NotificationQuery) => Promise<any>;
  readAllNotifications: () => Promise<any>;
  requestPushPermission: () => any;
  hasMore?: boolean;
  pushEnabled?: boolean;
  switcher: [boolean, () => void];
  notification: NotificationState;
}

const NotificationsContext = createContext<NotificationContextValue>({
  settings: [],
  capabilities: {
    push: true,
    email: true,
    text: true,
  },
  query: {},
  notification: initialState,
  updateNotificationsFlag: () => Promise.resolve([]),
  requestPushPermission: () => Promise.resolve([]),
  localUpdate: () => {},
  setQuery: () => {},
  hasMore: false,
  switcher: [false, () => {}],
  loadNotifications: () => Promise.resolve(initialState),
  readAllNotifications: () => Promise.resolve(),
});

export const NotificationProvider: FC<PropsWithChildren<any>> = ({ children }: PropsWithChildren<any>) => {
  const dispatch = useDispatch();
  const mounted = useMounted();
  const { user, setNotificationsFlag, setUserSettings } = useAuth();
  const displayOnlyUnread = useMemo(() => (user?.userSettings || {}).displayOnlyUnread, [!user?.userSettings?.displayOnlyUnread]);
  const [query, setQuery] = useState<NotificationQuery>({
    query: {},
    page: 1,
    pageSize: 20,
  });
  const [settings, localUpdate] = useState<NotificationValue[]>(user?.notifications || defaultNotificationsSettings);
  const [pushEnabled, setPushEnabled] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const notification = useSelector((state) => state.notification);
  // -----------------------------------------------------------------
  // This part will handle Push notification flow that
  // interacts with service worker
  useEffect(() => {
    if (mounted.current) {
      // trigger state checking
      if (navigator?.serviceWorker?.controller) {
        navigator.serviceWorker.controller.postMessage({
          type: 'PUSH_SUBSCRIPTION_CHECK',
        });
      }
    }
  }, [mounted.current]);

  useEffect(() => {
    const swMessageHandler = (e: MessageEvent) => {
      if (e.data && e.data.type === 'PUSH_SUBSCRIPTION_STATE') {
        console.log('[Sw.Client] Received subscription state from the service worker', e.data.value);
        setPushEnabled(e.data.value);
      }

      if (e.data && e.data.type === 'PUSH_SUBSCRIPTION_OK') {
        console.log('[Sw.Client] Successfully subscribed', e.data.value);
        setPushEnabled(true);
      }
    };

    // listen for message coming from sw
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', swMessageHandler);
    }

    return () => {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.removeEventListener('message', swMessageHandler);
      }
    };
  }, [user?._id]);

  const requestPushPermission = () => {
    if (navigator.serviceWorker && !pushEnabled) {
      // we will request push notification permission if it's not enabled but
      // the settings will request for it
      window.Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          if (!navigator.serviceWorker.controller) {
            console.warn('[Sw.Client] Ooops!! navigator.serviceWorker.controller is empty');
            return;
          }

          navigator.serviceWorker.controller.postMessage({
            type: 'PUSH_SUBSCRIPTION_REQ',
            value: {
              userId: user?._id,
              token: s.recursive('user/token'),
            },
          });
        }
      });
    }
  };
  // -----------------------------------------------------------------

  const updateNotificationsFlag = async (v: NotificationValue[]): Promise<NotificationValue[]> => setNotificationsFlag(v);

  const loadNotifications = useCallback(
    (q: NotificationQuery) => dispatch(loadUserNotifications({ ...q, displayOnlyUnread })),
    [displayOnlyUnread]
  );

  const readAllNotifications = () => dispatch(bulkReadAllNotifications());

  listenToNotifications(user?._id);

  useEffect(() => {
    if (user?._id && !notification.loaded) {
      loadNotifications(query);
    }
  }, [user?._id]);

  useEffect(() => {
    if (user?.notifications) {
      // change notification settings each time the user changes
      localUpdate(user?.notifications || defaultNotificationsSettings);
    }
  }, [user?.notifications]);

  useEffect(() => {
    if (notification.meta) {
      setHasMore(notification.meta.total > (notification.meta.page || 1) * (notification.meta.pageSize || 20));
    }
  }, [notification.meta]);

  const handleToggle = useCallback(() => {
    setUserSettings({
      displayOnlyUnread: !displayOnlyUnread
    });
  }, [displayOnlyUnread]);

  const filter = useCallback(
    (values: (Notification & { ids?: string[]; count: number })[]) => {
      if (!displayOnlyUnread) {
        // no filter applied here
        return values;
      }

      return values.filter(({ read }: Notification) => !read);
    },
    [displayOnlyUnread]
  );

  return (
    <NotificationsContext.Provider
      value={{
        settings,
        query,
        notification: {
          ...notification,
          values: filter(notification.values),
        },
        capabilities: {
          push: true,
          email: true,
          text: user?.phoneNumber !== '',
        },
        switcher: [Boolean(displayOnlyUnread), handleToggle],
        localUpdate,
        setQuery,
        loadNotifications,
        readAllNotifications,
        updateNotificationsFlag,
        requestPushPermission,
        hasMore,
        pushEnabled,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContext;
