import { createContext, useReducer, useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import invitation from 'src/api/invitationApi';
import useAuth from 'src/hooks/useAuth';

interface InvitationFlowProviderProps {
  children: ReactNode;
}

interface InvitationPayload {
  email: string;
  role: string;
  projectId: number;
}

interface State {
  processing: boolean;
  key: string | null;
  error?: string | null;
  data?: InvitationPayload & { ready?: boolean };
}

interface InvitationContextValue extends State {
  initialize: (key: string) => void;
  resurrect: () => Promise<string | null>;
  isPending: () => boolean;
  resolve: (key?: string) => Promise<void>;
  accept: () => Promise<void>;
  landInvitation: () => void;
}

enum ActionType {
  INITIALIZE = 'INITIALIZE',
  ERROR = 'ERROR',
  HOLD = 'HOLD',
  PROCESSING = 'PROCESSING',
  FLUSH = 'FLUSH',
}

interface Action<T = unknown> {
  type: ActionType;
  payload?: T;
}

const initialState: State = {
  processing: false,
  key: null,
};

const handlers: Record<ActionType, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: Action<string>) => ({
    ...state,
    key: action.payload,
  }),
  PROCESSING: (state: State, action: Action<boolean>) => ({
    ...state,
    processing: action.payload,
  }),
  HOLD: (state: State, action: Action<InvitationPayload>) => ({
    ...state,
    data: action.payload,
  }),
  ERROR: (state: State, action: Action<Error>) => ({
    ...state,
    error: action.payload.message,
  }),
  FLUSH: () => initialState,
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const InvitationContext = createContext<InvitationContextValue>({
  ...initialState,
  initialize: () => {},
  resurrect: () => Promise.resolve(null),
  isPending: () => false,
  resolve: () => Promise.resolve(null),
  accept: () => Promise.resolve(),
  landInvitation: () => {},
});

const LS_KEY = '__wauio_inv';

export const InvitationFlowProvider: FC<InvitationFlowProviderProps> = ({ children }: InvitationFlowProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const { isAuthenticated, user } = useAuth();

  const setError = (e: Error) => {
    dispatch({
      type: ActionType.ERROR,
      payload: {
        message: e.message,
      },
    });
  };

  const setProcessing = (value: boolean) => {
    dispatch({
      type: ActionType.PROCESSING,
      payload: value,
    });
  };

  const initialize = (key: string): void => {
    localStorage.setItem(LS_KEY, key);
    dispatch({
      type: ActionType.INITIALIZE,
      payload: key,
    });
  };

  const resurrect = async (): Promise<string | null> => {
    const key = localStorage.getItem(LS_KEY);
    if (key) {
      dispatch({
        type: ActionType.INITIALIZE,
        payload: key,
      });
    }

    return Promise.resolve(key);
  };

  const isPending = () => state.key && state.key !== '';

  const resolve = async (key?: string) => {
    if (key) {
      initialize(key);
    }

    if (!isAuthenticated()) {
      return;
    }

    setProcessing(true);
    invitation
      .resolve(key || state.key)
      .then(({ email, role, projectId }: InvitationPayload) => {
        if (email !== user.email) {
          setError(
            new Error(
              `The email you are logged in doesn't match the one from invitation, To accept the invitation please authenticate with ${email}`
            )
          );
          return;
        }

        dispatch({
          type: ActionType.HOLD,
          payload: { email, role, projectId, ready: true },
        });
      })
      .catch((e) => setError(new Error(`Please verify the link you're trying to use, we found this error: ${e.message}`)))
      .finally(() => setProcessing(false));
  };

  const accept = async () => {
    setProcessing(true);
    invitation
      .accept(state.data.email, state.data.projectId)
      .then(() => {
        localStorage.removeItem(LS_KEY);
        dispatch({ type: ActionType.FLUSH });
        navigate(`/dashboard/projects/${state.data.projectId}`);
      })
      .catch(setError)
      .finally(() => {
        setProcessing(false);
      });
  };

  useEffect(() => {
    resurrect();
  }, []);

  return (
    <InvitationContext.Provider
      value={{
        ...state,
        initialize,
        resurrect,
        isPending,
        resolve,
        accept,
        landInvitation: () => {
          navigate(`/invitation/${state.key}`);
        },
      }}
    >
      {children}
    </InvitationContext.Provider>
  );
};

export default InvitationContext;
