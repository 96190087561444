import { JiraDocument } from 'src/types/issue';

export const textParser = (originalText: string, config: any[] = []): { [key: string]: string } => {
  const result: { [key: string]: string } = {};

  let rest: string = originalText;
  config.forEach(({ key, search }: { key: string; search: string }) => {
    result[key] = rest.split(search).pop();
    rest = rest.replace(result[key], '').replace(search, '');
  });

  return result;
};

export const capitalize = (text: string): string => {
  if ((text || '').length === 0) {
    return '';
  }

  return text[0].toUpperCase() + text.substring(1);
};

export const truncate = (str: string, num: number = 100): string => {
  // If the length of str is less than or equal to num
  // just return str--don't truncate it.
  if (str.length <= num) {
    return str;
  }

  // Return str truncated with '...' concatenated to the end of str.
  return `${str.slice(0, num)}...`;
};

// export const jiraDocParser = (doc: JiraDocument) => {
//   const docs = doc ? (doc.content || []).map((c) => {
//     let cont = '';
//     c.content.forEach((co) => {
//       cont += `${co.text}`;
//     });
//     return cont;
//   }) : [];

//   return docs.join('\n\n');
// };

export const jiraDocParser = (doc: JiraDocument) => doc;
