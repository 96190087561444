import type { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import useInvitationFlow from '../hooks/useInvitationFlow';

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const { isPending, landInvitation } = useInvitationFlow();

  if (isAuthenticated()) {
    if (isPending()) {
      landInvitation();
      return null;
    }

    return <Navigate to="/account" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
