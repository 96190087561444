import { FC } from 'react';
import { useDispatch, useSelector } from 'src/store';
import { Autocomplete, Button, CircularProgress, IconButton, Popper, TextField } from '@material-ui/core';
import PushPinOutlined from '@material-ui/icons/PushPinOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { pinUserProject } from 'src/slices/pinnedProjects';
import { useNavigate } from 'react-router';

interface PinnedProject {
  projectId: number;
  projectName: string;
  isPinned: boolean;
}

const DashboardSidebarProjectPinner: FC = () => {
  const { pinnedProjects } = useSelector((state) => ({
    pinnedProjects: state.pinnedProjects,
  }));
  const dispatch = useDispatch();

  const navigate = useNavigate();

  return (
    <Autocomplete
      sx={{
        '& .MuiAutocomplete-groupLabel': {
          display: 'none',
        },
      }}
      size="small"
      loading={pinnedProjects.status.includes('loading')}
      options={pinnedProjects.projects.filter((item) => !item.isPinned)}
      autoHighlight
      getOptionLabel={(option: PinnedProject) => option.projectName}
      PopperComponent={(props) => (
        <Popper
          {...props}
          placement="bottom-start"
        />
      )}
      renderOption={(props, option: PinnedProject) => (
        <Button
          variant="text"
          onClick={() => {
            navigate(`/dashboard/projects/${option.projectId}/overview`);
          }}
          startIcon={
            (
              <IconButton
                size="small"
                onClick={(e) => {
                  dispatch(pinUserProject(option.projectId));
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                {!pinnedProjects.status.includes(`loading:${option.projectId}`) ? (
                  <PushPinOutlined sx={{ fontSize: 'large' }} />
                ) : (
                  <CircularProgress size={18} />
                )}
              </IconButton>
            )
          }
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            flexWrap: 'nowrap',
          }}
        >
          {option.projectName}
        </Button>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'project-name',
            startAdornment: (
              <>
                <SearchIcon />
                {params.InputProps.startAdornment}
              </>
            ),
            placeholder: 'Find project',
            style: { fontSize: '14px' }
          }}
        />
      )}
    />
  );
};

export default DashboardSidebarProjectPinner;
