import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import type { User, UserSettings } from '../types/user';
import { authApi } from 'src/api/authApi';
import { settingsApi } from 'src/api/settingsApi';
import { axiosRestInstance, axiosGraqhqlInstance } from 'src/api/axios';
import { storage as s } from '../utils/storage';
import { NotificationValue } from 'src/types/notification';

interface State {
  isInitialized: boolean;
  // isAuthenticated: boolean;
  user: User | null;
}

export type UserUpdateInput = {
  id: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  country?: string;
  avatar?: string;
  notifications?: NotificationValue[];
};

interface AuthContextValue extends State {
  platform: 'JWT';
  login: (email: string, password: string, expiresIn?: string) => Promise<void>;
  logout: () => Promise<void>;
  isAuthenticated: () => boolean;
  register: (email: string, firstName: string, lastName: string, password: string) => Promise<void>;
  passwordRecovery: (email: string) => Promise<void>;
  passwordReset: (token: string, password: string, checkPassword: string) => Promise<void>;
  userUpdate: (user: UserUpdateInput) => Promise<void>;
  setNotificationsFlag?: (values: NotificationValue[]) => Promise<NotificationValue[]>;
  passwordUpdate: (id: string, newPassword: string, checkPassword: string) => Promise<void>;
  updateAvatar: (id: string, formData: any) => Promise<void>;
  removeAvatar: (id: string) => Promise<void>;
  setUserSettings: (settings: Partial<UserSettings>) => Promise<UserSettings>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    // isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type UpdateUserAction = {
  type: 'UPDATE_USER';
  payload: {
    user: User;
  };
};

type SetNotificationFlagAction = {
  type: 'UPDATE_USER_NOTIFICATIONS_FLAG';
  payload: {
    values: NotificationValue[];
  };
};

type SetUserSettings = {
  type: 'SET_USER_SETTINGS';
  payload: {
    settings: UserSettings;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | SetNotificationFlagAction
  | SetUserSettings
  | UpdateUserAction;

const initialState: State = {
  // isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const setSession = (user: User | null): void => {
  if (user) {
    const { token } = user;
    s.record('user', { token });
    axiosGraqhqlInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    axiosRestInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    s.record('user', null);
    delete axiosGraqhqlInstance.defaults.headers.common.Authorization;
    delete axiosRestInstance.defaults.headers.common.Authorization;
  }
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const {
      // isAuthenticated,
      user,
    } = action.payload;

    return {
      ...state,
      // isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      // isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    // isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state: State, action: RegisterAction): State => {
    const { user } = action.payload;

    return {
      ...state,
      // isAuthenticated: true,
      user,
    };
  },
  UPDATE_USER: (state: State, action: UpdateUserAction): State => ({
    ...state,
    // isAuthenticated: true,
    user: {
      ...state.user,
      ...action.payload,
    },
  }),
  UPDATE_USER_NOTIFICATIONS_FLAG: (state: State, action: SetNotificationFlagAction) => ({
    ...state,
    user: {
      ...state.user,
      notifications: action.payload.values,
    },
  }),
  SET_USER_SETTINGS: (state: State, action: SetUserSettings) => ({
    ...state,
    user: {
      ...state.user,
      userSettings: action.payload.settings,
    },
  }),
};

const reducer = (state: State, action: Action): State => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  isAuthenticated: () => false,
  register: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve(),
  userUpdate: () => Promise.resolve(),
  setNotificationsFlag: () => Promise.resolve([]),
  passwordUpdate: () => Promise.resolve(),
  updateAvatar: () => Promise.resolve(),
  removeAvatar: () => Promise.resolve(),
  setUserSettings: () => Promise.reject(new Error('[setUserSettings] not implemented')),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const reduxDispatch = useDispatch();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const userStored = s.recursive('user');

        if (userStored) {
          const { token } = userStored;
          const response = await authApi.me(token);

          const { me } = response.data;

          if (me) {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                // isAuthenticated: true,
                user: { ...me },
              },
            });
          } else {
            setSession(null);
            dispatch({
              type: 'INITIALIZE',
              payload: {
                // isAuthenticated: false,
                user: null,
              },
            });
          }
        } else {
          setSession(null);
          dispatch({
            type: 'INITIALIZE',
            payload: {
              // isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        // console.error(err);
        setSession(null);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            // isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
    // eslint-disable-next-line no-underscore-dangle
  }, [state?.user?._id]);

  const login = async (email: string, password: string, expiresIn?: string): Promise<void> => {
    const response = await authApi.login(email, password, expiresIn);
    const { userLogin } = response.data;

    if (userLogin) {
      setSession(userLogin);
      dispatch({
        type: 'LOGIN',
        payload: {
          user: { ...userLogin },
        },
      });
    }
  };

  const logout = async (): Promise<void> => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
    // Clean all redux states
    [
      'project',
      'mail',
      'kanban',
      'deliverableKanban',
      'chat',
      'calendar',
      'activity',
      'issue',
      'comment',
      'notification',
    ].forEach((slice: string) => {
      reduxDispatch({ type: `${slice}/clearState` });
    });
  };

  const register = async (email: string, firstName: string, lastName: string, password: string): Promise<void> => {
    const response = await authApi.register(email, firstName, lastName, password);
    const { userCreate } = response.data;
    if (userCreate) {
      setSession(userCreate);
      dispatch({
        type: 'REGISTER',
        payload: {
          user: { ...userCreate },
        },
      });
    }
  };

  const passwordRecovery = async (email: string): Promise<void> => {
    const response = await authApi.passwordRecovery(email);
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { passwordRecovery } = response.data;
    if (!passwordRecovery) {
      throw new Error('Failed to send email');
    }
  };

  const passwordReset = async (token: string, password: string, passwordCheck: string): Promise<void> => {
    const response = await authApi.passwordReset(token, password, passwordCheck);
    const { passwordSet } = response.data;
    if (!passwordSet) {
      throw new Error('Failed to reset password');
    }
  };

  const setNotificationsFlag = async (values: NotificationValue[]): Promise<NotificationValue[]> => {
    const notifications = await authApi.setNotificationsFlag(values);
    if (notifications) {
      dispatch({
        type: 'UPDATE_USER_NOTIFICATIONS_FLAG',
        payload: {
          values: notifications,
        },
      });
    }

    return notifications;
  };

  const userUpdate = async (updates: UserUpdateInput): Promise<void> => {
    const response = await authApi.userUpdate(updates);
    const { updateUser } = response.data;
    if (updateUser) {
      dispatch({
        type: 'UPDATE_USER',
        payload: {
          user: { ...updateUser },
        },
      });
    }
  };

  const setUserSettings = async (settings: Partial<UserSettings>): Promise<UserSettings> => {
    const newSettings = await settingsApi.settingsUpdate({
      // place default value here (if needed)
      ...(state.user?.userSettings || {}),
      ...settings
    });
    if (newSettings) {
      dispatch({
        type: 'SET_USER_SETTINGS',
        payload: {
          settings: newSettings
        },
      });

      return newSettings;
    }

    // the old one
    return (state.user?.userSettings || {});
  };

  const updateAvatar = async (id: string, formData: any): Promise<void> => {
    const uploadResponse = await axiosRestInstance.post('/files', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    // eslint-disable-next-line no-irregular-whitespace
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _id } = uploadResponse.data[0];
    const response = await authApi.userUpdate({ id, avatar: _id });
    const { updateUser } = response.data;
    if (!updateUser) {
      throw new Error('Failed to set avatar');
    }

    dispatch({
      type: 'UPDATE_USER',
      payload: {
        user: { ...updateUser },
      },
    });
  };

  const removeAvatar = async (id: string): Promise<void> => {
    const res = await axiosRestInstance.delete(`/files/${id}`);
    if (res) {
      dispatch({
        type: 'UPDATE_USER',
        payload: {
          user: { ...state.user, avatar: null },
        },
      });
    }
  };

  const passwordUpdate = async (id: string, newPassword: string, checkPassword: string): Promise<void> => {
    const response = await authApi.passwordUpdate(id, newPassword, checkPassword);
    const { updatePassword } = response.data;
    if (!updatePassword) {
      throw new Error('Failed to update password');
    }
  };

  const isAuthenticated = (): boolean => {
    const userStored = s.recursive('user');
    if (userStored) {
      const { token } = userStored;
      if (!token) {
        return false;
      }

      const decoded: any = jwtDecode(token);
      const currentTime = Date.now() / 1000;

      return decoded.exp > currentTime;
    }
    setSession(null);
    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'JWT',
        login,
        logout,
        isAuthenticated,
        register,
        passwordRecovery,
        passwordReset,
        userUpdate,
        setNotificationsFlag,
        passwordUpdate,
        updateAvatar,
        removeAvatar,
        setUserSettings,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
