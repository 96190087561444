/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { projectApi } from 'src/api/projectApi';
import { JiraProject as Project } from 'src/types/project';
import { ObjectCollection } from 'src/types/collection';

export interface PinnedProject {
  projectId: number;
  projectName: string;
  isPinned: boolean;
}

export interface PinnedProjectState {
  projects: PinnedProject[];
  status: string;
}

const initialState: PinnedProjectState = {
  projects: [],
  status: 'idle',
};

const createPinnedProjectArray = async (): Promise<PinnedProject[]> => {
  const projects: ObjectCollection<Project> = await projectApi.search();
  const res = await projectApi.getUserPinnedProject();

  return projects.values.map((item) => ({
    projectId: item.id,
    projectName: item.name,
    isPinned: res.includes(item.id),
  }));
};

export const fetchUserPinnedProject = createAsyncThunk<PinnedProject[]>('pinnedProjects/fetch', async () => {
  try {
    return await createPinnedProjectArray();
  } catch (e) {
    if (!e.response) {
      throw e;
    }

    return [];
  }
});

export const pinUserProject = createAsyncThunk('pinnedProjects/pin', async (projectId: number, { rejectWithValue }) => {
  try {
    await projectApi.pinUserProject(projectId);

    return await createPinnedProjectArray();
  } catch (e) {
    if (!e.response) {
      throw e;
    }

    return rejectWithValue(e.response);
  }
});

export const unPinUserProject = createAsyncThunk(
  'pinnedProjects/unpin',
  async (projectId: number, { rejectWithValue }) => {
    try {
      await projectApi.unPinUserProject(projectId);

      return await createPinnedProjectArray();
    } catch (e) {
      if (!e.response) {
        throw e;
      }

      return rejectWithValue(e.response);
    }
  }
);

const slice = createSlice({
  name: 'pinnedProjects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchUserPinnedProject.fulfilled,
      (state: PinnedProjectState, action: PayloadAction<PinnedProject[]>) => {
        state.status = 'succeeded';
        state.projects = action.payload;
      }
    );

    builder.addCase(pinUserProject.pending, (state: PinnedProjectState, action: any) => {
      state.status = `loading:${action.meta.arg}`;
    });

    builder.addCase(pinUserProject.fulfilled, (state: PinnedProjectState, action: PayloadAction<PinnedProject[]>) => {
      state.status = 'succeeded';
      state.projects = action.payload;
    });

    builder.addCase(pinUserProject.rejected, (state: PinnedProjectState, action: any) => {
      state.status = `rejected:${action.meta.arg}`;
    });

    builder.addCase(unPinUserProject.pending, (state: PinnedProjectState, action: any) => {
      state.status = `loading:${action.meta.arg}`;
    });

    builder.addCase(unPinUserProject.fulfilled, (state: PinnedProjectState, action: PayloadAction<PinnedProject[]>) => {
      state.status = 'succeeded';
      state.projects = action.payload;
    });

    builder.addCase(unPinUserProject.rejected, (state: PinnedProjectState, action: any) => {
      state.status = `rejected:${action.meta.arg}`;
    });
  },
});

export const { reducer } = slice;

export default slice;
